import React from 'react';

import Article from "../layout/Article";
const API_PATH = "article_personnel_civils.json";
const TITLE = 'Civils de la Défense - Pourquoi nous rejoindre'

const Article_PersonnelCivil = () => {
    return (
        <Article
            access_path={API_PATH}
            seo={TITLE}
        />
    );
}

export default Article_PersonnelCivil;