import React from 'react';

import Article from "../layout/Article"
const API_PATH = 'article_recrutements.json';
const TITLE = 'Civils de la Défense - Concours';

const Article_Concours = () => {
    return (
        <Article
            access_path={API_PATH}
            seo={TITLE}
        />
    );
}

export default Article_Concours;