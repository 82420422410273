import Parser from 'html-react-parser';

const parserOptions = {
    decodeEntities: true,
    replace: (domNode) => {
        if (domNode.name === 'video') {
            return <video {...domNode.attribs} />;
        }
    },
};

function replaceHtmlEntities(htmlString) {
    const map = {
        '&lt;': '<',
        '&gt;': '>',
    };
    return htmlString.replace(/&lt;|&gt;/g, (m) => map[m]);
}

const ParsedParagraph = ({ text, ...otherProps }) => (
    <>
        {Parser(replaceHtmlEntities(text), { parserOptions })}
    </>
)

export default ParsedParagraph