import React  from 'react';
import Moment from 'react-moment';

Moment.globalLocale = 'fr';

export default class MomentYears extends React.Component {
    render() {
        const dateToFormat = new Date();
        return (
            <Moment locale="fr" date={dateToFormat} format="YYYY"/>
        );
    }
}