import React, { useEffect, useState } from 'react';
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import Parser from 'html-react-parser';
import '../../sass/components/_metiers_homes.scss';
import { Link } from "react-router-dom";

function Metiers_Home() {

    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = 'metiers_homes.json';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true)
            })
            .catch(err => {
                setError(err);
                setLoad(true);
            }
            );
    }, []);
        if (load && data[0]) {
            return (
                <section id="metiers">
                    <h2 className="ta-c fr-pt-5w fr-mb-5w">Découvrez nos métiers</h2>
                        <div className="fr-mb-6v fr-container">
                            <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center">
                                { error
                                    ? <p>{error.message}</p>
                                    : data.map((GetData, index) =>
                                    <>
                                        <div class="fr-card fr-enlarge-link fr-card--horizontal-half fr-col-12 fr-col-md-6 fr-mb-3w fr-grid-row" key={index}>
                                            <div class="fr-card__body fr-col-6">
                                                <div class="fr-card__content">
                                                    <h3 className="fr-card__title" style={{height:"3.5rem"}}>
                                                        <Link to={"/metier/" + (GetData.metier.id)} className="btn-unfilled" title={Parser(GetData.metier.intituler).toLowerCase()}>{Parser(GetData.metier.intituler.toUpperCase())}</Link>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="fr-card__img fr-col-6 banner-filter">
                                                {GetData.metier.media.contentUrl.endsWith('.webm') ?
                                                    <video src={`${ApiConfig.baseURL_IMG + GetData.metier.media.contentUrl}`} autoPlay muted loop className="fr-responsive-video" /> :
                                                    <img src={`${ApiConfig.baseURL_IMG + GetData.metier.media.contentUrl}`} alt={GetData.metier.intituler} className="fr-responsive-img" />
                                                }
                                                <div className="filter-f"></div>
                                            </div>
                                        </div>
                                    </>
                                    )
                                }
                            </div>
                        </div>
                </section>
            );
        }

    else if (load && !data[0]) {
        return null
    }
    
    else {
        return (
            <div>
                <p>Chargement</p>
            </div>
        );
    }
};


export default Metiers_Home;