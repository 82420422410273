import React from 'react';

import Article from "../layout/Article"
const API_PATH = 'article_emplois.json';
const TITLE = 'Civils de la Défense - Contractuel'

const Article_Emplois = () => {
    return (
        <Article
            access_path={API_PATH}
            seo={TITLE}
        />
    );
}

export default Article_Emplois;