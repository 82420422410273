import React, { Component } from 'react'
import { useState, useEffect } from 'react'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components';
import "../../sass/components/_bot.scss";
import { Link } from 'react-router-dom';
import { ApiConfig } from '../Api/ApiConfig';


const ChatBots = () => {
  const [bot, setBot] = useState([]);
  const [load, setLoad] = useState(false);
  const API_PATH = 'bots.json';

  useEffect(() => {
    fetch(`${ApiConfig.baseURL + API_PATH}`)
      .then(res => res.json()).then(res => {
        setBot(res[0]);
        setLoad(true)
      })
      .catch(e => {
        console.log(new Error("Asynchronous error"));
      });
  }, []);

  useEffect(() => {
    console.error = () => { }; // ignore console errors
  }, []);

  if (load) {
    return (
      <Bot bot={bot} />
    )
  }
}

class Bot extends Component {
  render() {

    const { bot } = this.props;

    const theme = {
      background: '#D6F5FF',
      fontFamily: 'Marianne',
      headerBgColor: '#000091',
      headerFontColor: '#fff',
      headerFontSize: '15px',
      botBubbleColor: '#000091',
      botFontColor: '#fff',
      userBubbleColor: '#00171F',
      userFontColor: '#fff',
    };

    let steps = {}
    try {
      steps = JSON.parse(bot.steps, function (key, value) {
        if (key === "name") {
          return undefined; // Supprimer la propriété "name"
        }
        return value;
      });
    }
    catch (error) {
      console.error("Une erreur s'est produite dans le composant ChatBot :", error);
      return null; // ou un message d'erreur alternatif, ou un fallback
    }


    const components = {
      Hello: <Hello bot={bot} />,
      Bye: <Bye bot={bot}/>,
      Stages: <Stages bot={bot}/>,
      Apprentissage: <Apprentissage bot={bot}/>,
      CDD: <CDD bot={bot}/>,
      CDI: <CDI bot={bot}/>,
      Concours: <Concours bot={bot}/>,
      Mobilite: <Mobilite bot={bot}/>,
    };

    return (
      <section id="bot" aria-label="chatbot">
        <ThemeProvider theme={theme}>
          <ChatBot
            width="350px"
            botDelay="2000"
            floating={true}
            floatingIcon={ApiConfig.baseURL_IMG + bot.icon.contentUrl}
            recognitionLang="fr"
            recognitionEnable={false}
            enableMobileAutoFocus={true}
            headerTitle={bot.botTitle}
            speechSynthesis={{ enable: false, lang: "fr" }}
            placeholder="Veuillez selectionner votre réponse !"
            userAvatar={ApiConfig.baseURL_IMG + bot.user.contentUrl}
            botAvatar={ApiConfig.baseURL_IMG + bot.avatar.contentUrl}
            steps={steps.map((step) => ({
              ...step,
              component: components[step.component],
            }))}
          />
        </ThemeProvider>
      </section>
    );
  }
}

class Hello extends Component {
  render() {
    const { bot } = this.props;
    return (
      <>
        <p>
          {bot.helloText}{" "}
          <span role="img" aria-label="Bye">
            🌄
          </span>
          <br />
          <br />
          {bot.helloSubText && (
            <React.Fragment>
              {bot.helloSubText}
              <br />
              <br />
            </React.Fragment>
          )}
          {bot.hello.contentUrl.endsWith(".webm") ? (
            <video
              src={ApiConfig.baseURL_IMG + bot.hello.contentUrl}
              autoPlay
              muted
              controls
              loop
              style={{ backgroundColor: "#000091" }}
              alt="Bonjour"
            />
          ) : (
            <img
              src={ApiConfig.baseURL_IMG + bot.hello.contentUrl}
              style={{ backgroundColor: "#000091" }}
              alt="Bonjour"
            />
          )}
        </p>
      </>
    );
  }
}

class Bye extends Component {
  render() {
    const { bot } = this.props;
    return (
      <>
        <p>
          {bot.byeText}{" "}
          <span role="img" aria-label="Bye">
            👋
          </span>
          <br />
          <br />
          {bot.byeSubText && (
            <React.Fragment>
              {bot.byeSubText}
              <br />
              <br />
            </React.Fragment>
          )}
          {bot.bye.contentUrl.endsWith(".webm") ? (
            <video
              src={ApiConfig.baseURL_IMG + bot.bye.contentUrl}
              autoPlay
              muted
              controls
              loop
              style={{ backgroundColor: "#000091" }}
              alt="Au revoir"
            />
          ) : (
            <img
              src={ApiConfig.baseURL_IMG + bot.bye.contentUrl}
              style={{ backgroundColor: "#000091" }}
              alt="Au revoir"
            />
          )}
        </p>
      </>
    );
}
}

class Stages extends Component {
  render() {
    const { bot } = this.props;
    return <>
      <p>{bot.stageText}
        <br /><br />
        {bot.stageSubText && (
          <React.Fragment>
            {bot.stageSubText}
            <br />
            <br />
          </React.Fragment>
        )}
        <a href={bot.stageUrl} target="_blank" title={bot.stageTitle} rel="noopener noreferrer">
          <span role="img" aria-label="finger">👉 {bot.stageTitle}</span></a>
      </p>
    </>
  }
}

class Apprentissage extends Component {
  render() {
    const { bot } = this.props;
    return <>
      <p>{bot.apprentissageText}
        <br /><br />
        {bot.apprentissageSubText && (
          <React.Fragment>
            {bot.apprentissageSubText}
            <br />
            <br />
          </React.Fragment>
        )}
        <a href={bot.apprentissageUrl} target="_blank" title={bot.apprentissageTitle} rel="noopener noreferrer">
          <span role="img" aria-label="finger">👉 {bot.apprentissageTitle}</span></a>
      </p>
    </>
  }
}

class CDD extends Component {
  render() {
    const { bot } = this.props;
    return <>
      <p>{bot.cddText}
        <br /><br />
        {bot.cddSubText && (
          <React.Fragment>
            {bot.cddSubText}
            <br />
            <br />
          </React.Fragment>
        )}
        <a href={bot.cddUrl} target="_blank" title={bot.cddTitle} rel="noopener noreferrer">
          <span role="img" aria-label="finger">👉 {bot.cddTitle}</span></a>
      </p>
    </>
  }
}

class CDI extends Component {
  render() {
    const { bot } = this.props;
    return <>
      <p>{bot.cdiText}
        <br /><br />
        {bot.cdiSubText && (
          <React.Fragment>
            {bot.cdiSubText}
            <br />
            <br />
          </React.Fragment>
        )}
        <a href={bot.cdiUrl} target="_blank" title={bot.cdiTitle} rel="noopener noreferrer">
          <span role="img" aria-label="finger">👉 {bot.cdiTitle}</span></a>
      </p>
    </>
  }
}

class Mobilite extends Component {
  render() {
    const { bot } = this.props;
    return <>
      <p>{bot.mobiliteText}
        <br /><br />
        {bot.mobiliteSubText && (
          <React.Fragment>
            {bot.mobiliteSubText}
            <br />
            <br />
          </React.Fragment>
        )}
        Ce type de recrutement concerne exclusivement les candidats déjà fonctionnaires titulaires auprès d’une autre administration.
        <br /><br />
        <a href={bot.mobiliteUrl} target="_blank" title={bot.mobiliteTitle} rel="noopener noreferrer">
          <span role="img" aria-label="finger">👉 {bot.mobiliteTitle}</span></a>
      </p>
    </>
  }
}

class Concours extends Component {
  render() {
    const { bot } = this.props;
    return <>
      <p>{bot.concoursText}
        <br /><br />
        {bot.concoursSubText && (
          <React.Fragment>
            {bot.concoursSubText}
            <br />
            <br />
          </React.Fragment>
        )}
        <Link to={bot.concoursUrl} title={bot.concoursTitle}>
          <span role="img" aria-label="finger">👉 {bot.concoursTitle}</span>
        </Link>
      </p>
    </>
  }
}

export default ChatBots;