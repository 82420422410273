import React, { useState, useEffect } from "react";
import "../../sass/components/_scroll.scss";
import { Link } from 'react-scroll'
// import Icon from "../../img/icon/scroll/arrow-up-circle-line.svg";

const Scroll = () => {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 900);
        });
      }, []); 

    return (
        <div>
            <Link 
            title="Revenir en haut du site"
            to="root"
            smooth={true}
            duration={1500}
            href="#root"
            className={scroll ? "scrollUp fr-btn rf-btn" : "banner"}
            type="button"
            id="scrollBtn"
            data-action="scroll#top" data-controller="scroll"
            >
                ▲
                {/* <img src={Icon} alt="Scroll"/> */}
        </Link>
        </div>
    );
}
 
export default Scroll;