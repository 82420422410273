import React, { useMemo, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from "../../img/logo/Logo_CivilsDeLaDefense.svg";
import Logo2 from "../../img/logo/Logo_CivilsDeLaDefenseWhite.svg";
import Dark from '../../img/artwork/dark.svg'
import Light from '../../img/artwork/light.svg'
import System from '../../img/artwork/system.svg'
import BurgerMenu from './BurgerMenu';
import { getData } from '../Api/getData';
import { ApiConfig } from '../Api/ApiConfig';
import {isWithinInterval} from "date-fns";

const Header = () => {

    const [data, setData] = useState([]);
    const API_PATH = 'menu_actives.json';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res[0]);
            }).catch(err => {
                console.log(err);
            }
            );
    }, []);

    const [dataSlider, setDataSlider] = useState([]);
    const API_PATH_SLIDER = 'sliders.json';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH_SLIDER}`)
            .then(res => {
                setDataSlider(res);
            }).catch(err => {
                console.log(err);
            }
        );
    }, []);

    const filteredDataSlider = dataSlider.filter(slider => isWithinInterval(new Date(), { start: slider.startDate, end: slider.endDate}));


    /* Creating a state variable called active and setting it to false. */
    const [active, setActive] = useState(false);
    
    /* This is a simple example of how to use state in React. */
    const [schemeState, setschemeState] = useState([localStorage.getItem('scheme') || 'system'])
    const SCHEME_ATTRIBUTE = 'data-fr-scheme'

    /* Creating a button that will be used to open the Accessibility Configuration Panel. */
    const AccessibilityBtn = useMemo(() => {
        return <div
            id="accessconfig"
            data-accessconfig-buttonname="Accessibilité"
            data-accessconfig-params='{
                "Prefix" : "a42-ac",
                "Contrast": false,
                "Font" : true,
                "LineSpacing": true,
                "Justification": true,
                "ImageReplacement": false
            }'
        >
        </div>
    }, [])

    /* This is a React Hook that is being used to change the color scheme of the app. */
    useEffect(() => {
        switch (schemeState[0]) {
            case 'dark':
                localStorage.setItem('scheme', 'dark')
                document.body.classList.add('dark-mode')
                document.getElementsByTagName('html')[0].setAttribute(SCHEME_ATTRIBUTE, 'dark')
                break

            case 'light':
                localStorage.setItem('scheme', 'light')
                document.body.classList.remove('dark-mode')
                document.getElementsByTagName('html')[0].setAttribute(SCHEME_ATTRIBUTE, 'light')
                break

            case 'system':
                localStorage.setItem('scheme', 'system')
                document.body.classList.remove('dark-mode')
                document.getElementsByTagName('html')[0].setAttribute(SCHEME_ATTRIBUTE, 'system')
                break

            default:
                return (null)
        }
    }, [schemeState])

    /**
     * It creates a dialog box that allows the user to change the color scheme of the website.
     */
    const Dialog = () => <section aria-labelledby="fr-theme-modal-title">
        <div id="filter-dialog"></div>
        <div className="fr-container fr-container--fluid fr-container-md" style={{ "position": "relative", "z-index": "100" }}>
            <div className="fr-grid-row fr-grid-row--center">
                <div className="fr-col-12 fr-col-md-6 fr-col-lg-4">
                    <div className="fr-modal__body">
                        <div className="fr-modal__header">
                            <button className="fr-link--close fr-link" aria-controls="fr-theme-modal" onClick={() => setActive(false)}>Fermer</button>
                        </div>
                        <div className="fr-modal__content">
                            <p id="fr-theme-modal-title" className="fr-modal__title">
                                Paramètres d’affichage
                            </p>
                            <div id="fr-display" className="fr-form-group fr-display">
                                <div className="fr-form-group">
                                    <fieldset className="fr-fieldset">
                                        <legend className="fr-fieldset__legend fr-text--regular" id="-legend">
                                            Choisissez un thème pour personnaliser l’apparence du site.
                                        </legend>
                                        <div className="fr-fieldset__content">
                                            <div className="fr-radio-group fr-radio-rich">
                                                <input value="light" type="radio" id="fr-radios-theme-light" name="fr-radios-theme" checked={schemeState[0] === 'light'} onChange={() => setschemeState(['light'])} />
                                                <label className="fr-label" htmlFor="fr-radios-theme-light">Thème clair
                                                    <span className="fr-hint-text">Affichage du site en thème clair.</span>
                                                </label>
                                                <div className="fr-radio-rich__img" data-fr-inject-svg><img src={Light} alt="" /></div>
                                            </div>
                                            <div className="fr-radio-group fr-radio-rich">
                                                <input value="dark" type="radio" id="fr-radios-theme-dark" name="fr-radios-theme" checked={schemeState[0] === 'dark'} onChange={() => setschemeState(['dark'])} />
                                                <label className="fr-label" htmlFor="fr-radios-theme-dark">Thème sombre
                                                    <span className="fr-hint-text">Affichage du site en thème sombre.</span>
                                                </label>
                                                <div className="fr-radio-rich__img" data-fr-inject-svg><img src={Dark} alt="" /></div>
                                            </div>
                                            <div className="fr-radio-group fr-radio-rich">
                                                <input value="system" type="radio" id="fr-radios-theme-system" name="fr-radios-theme" checked={schemeState[0] === 'system'} onChange={() => setschemeState(['system'])} />
                                                <label className="fr-label" htmlFor="fr-radios-theme-system">Système
                                                    <span className="fr-hint-text">Utilise les paramètres système.</span>
                                                </label>
                                                <div className="fr-radio-rich__img" data-fr-inject-svg><img src={System} alt="" /></div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;

    return (
        <>
            <div className="fr-skiplinks">
                <nav className="fr-container" aria-label="Accès rapide">
                    <ul className="fr-skiplinks__list">
                        <li><button type="button"><a className="fr-link" href="#header">Menu</a></button></li>
                        <li><button type="button"><a className="fr-link" href="#main">Contenu</a></button></li>
                        <li><button type="button"><a className="fr-link" href="#footer">Pied de page</a></button></li>
                    </ul>
                </nav>
            </div>

            <header className="fr-header" id="header">
                <div className="fr-header__body">
                    <div className="fr-container">
                        <div className="fr-header__body-row">
                            <div className="fr-header__brand fr-enlarge-link">
                                <div className="fr-header__brand-top">
                                    <a href="/">
                                        <div className="fr-header__logo">
                                            <p className="fr-logo">
                                                Ministère<br />des Armées
                                            </p>
                                        </div>
                                    </a>
                                    <Link to="/" title="Logo Civils de la Défense - Accueil">
                                        <div className="fr-header__operator">
                                            <img src={Logo} className="fr-responsive-img logo1" alt="Logo Civils de la Défense" />
                                            <img src={Logo2} className="fr-responsive-img logo2" alt="Logo Civils de la Défense" />
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div id="burgermenu" data-fr-opened="false" aria-haspopup="true" aria-expanded="false" title="Menu" type="button">
                                <BurgerMenu areSlidersActives={filteredDataSlider.length > 0} />
                            </div>

                            <div className="fr-header__tools">
                                <div className="fr-header__tools-links">
                                    <ul className="fr-links-group">
                                        {/* <li id="MetierIcon">
                                            <button type="button" className="fr-link" id="link-metiers">
                                                <Link to="/metiers" className="fr-link">
                                                    <img src={MetierIcon} alt="Icon Métiers" style={{ paddingRight: "8px", width: "25px" }} /> Métiers
                                                </Link>
                                            </button>
                                        </li>
                                        <li id="MetierIconDark">
                                            <button type="button" className="fr-link" id="link-metiers_dark">
                                                <Link to="/metiers" className="fr-link">
                                                    <img src={MetierIconDark} alt="Icon Métiers" style={{ paddingRight: "8px", width: "25px" }} /> Métiers
                                                </Link>
                                            </button>
                                        </li>
                                        <li>
                                            <button type="button" className="fr-link fr-fi-edit-line" id="link-recrutement">
                                                <Link to="/recrutement">
                                                    Recrutement
                                                </Link>
                                            </button>
                                        </li> */}

                                        <li className="fr-link fr-link--icon-left">
                                            {AccessibilityBtn}
                                        </li>

                                        <li>
                                            <button onClick={() => setActive(true)} className="fr-link fr-icon-theme-fill fr-link--icon-left" aria-controls="fr-theme-modal">Paramètres d'affichage</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fr-header__menu fr-modal" id="modal-835">
                    <div className="fr-container">
                        <button className="fr-link--close fr-link" aria-controls="modal-835">Fermer</button>
                        <div className="fr-header__menu-links"></div>
                        <nav className="fr-nav" aria-label="Menu principal">
                            <ul className="fr-nav__list">
                                {
                                    filteredDataSlider.length > 0 ? <li className="fr-nav__item">
                                        <Link to="/#slider" className="fr-nav__link" target="_self">Actualités</Link>
                                    </li> : null
                                }
                                <li className="fr-nav__item">
                                    <NavLink to="/metiers" className="fr-nav__link" target="_self">Nos métiers</NavLink>
                                </li>
                                {data.isActiveArticleChiffres ?
                                    <li className="fr-nav__item">
                                        <NavLink to="/chiffres" className="fr-nav__link" target="_self">Chiffres clés</NavLink>
                                    </li>
                                    : null
                                }
                                {data.isActiveArticleInclusions ?
                                    <li className="fr-nav__item">
                                        <NavLink to="/inclusion" className="fr-nav__link" target="_self">Handicap</NavLink>
                                    </li>
                                    : null
                                }
                                <li className="fr-nav__item">
                                    <NavLink to="/recrutement" className="fr-nav__link" target="_self">Rechercher une offre</NavLink>
                                </li>
                                <li className="fr-nav__item">
                                    <NavLink to="/admissio" className="fr-nav__link" target="_self">Concours Civils</NavLink>
                                </li>
                                {data.isActiveArticleConcours ?
                                <li className="fr-nav__item">
                                    <NavLink to="/concours" className="fr-nav__link" target="_self">Concours</NavLink>
                                </li>
                                    : null
                                }
                                {data.isActiveArticleJury ?
                                    <li className="fr-nav__item">
                                        <NavLink to="/jury" className="fr-nav__link" target="_self">Jury</NavLink>
                                    </li>
                                    : null
                                }
                                {data.isActiveDga ?
                                    <li className="fr-nav__item">
                                        <NavLink to="/dga" className="fr-nav__link" target="_self">DGA</NavLink>
                                    </li>
                                    : null
                                }
                                {data.isActiveDgse ?
                                    <li className="fr-nav__item">
                                        <NavLink to="/dgse" className="fr-nav__link" target="_self">DGSE</NavLink>
                                    </li>
                                    : null
                                }
                                {data.isActiveQuizz ?
                                    <li className="fr-nav__item">
                                        <NavLink to="/quiz" className="fr-nav__link" target="_self">Quiz</NavLink>
                                    </li>
                                    : null
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            {/* Active au click le mode nuit (Switch Thème / Dark Mode) */}
            {active ? <Dialog /> : null}
            </header>


        </>
    );
}

export default Header;