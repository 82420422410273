import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import Parser from 'html-react-parser';
import Footer from '../99_Footer/Footer';
import { Facebook } from 'react-content-loader'

const Contact = (props) => {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = "contacts.json";
    const TITLE = 'Civils de la Défense - Contact'

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true)
            }).catch(err => {
                setError(err);
                setLoad(true);
            }
            );
    }, []);

    if (load) {
        return (
            <>
                <Helmet><title>{TITLE}</title></Helmet>
                {error
                    ? <p>{error.message}</p>
                    : data.map((GetData, index) =>
                        <main id="main" role="main" key={index} className="fr-mb-10w">
                            {GetData.media.contentUrl.endsWith('.webm') ?
                                <div className="video-container">
                                    <video src={`${ApiConfig.baseURL_IMG}${GetData.media.contentUrl}`} autoPlay muted loop className="video" />
                                    <div className="filter"></div>
                                    <h1>Contact</h1>
                                </div> :
                                <aside style={{ backgroundImage: `url(${ApiConfig.baseURL_IMG + GetData.media.contentUrl})` }} className="banner-filter">
                                    <div className="filter"></div>
                                    <h1>Contact</h1>
                                </aside>
                            }

                            <div className="fr-container fr-mt-5w">
                                <h2 className="fr-mt-5w">{GetData.titre1}</h2>
                                {Parser(GetData.texte1)}

                                <h2 className="fr-mt-5w">{GetData.titre2}</h2>
                                {Parser(GetData.texte2)}
                            </div>
                        </main>
                    )
                }
                <Footer />
            </>
        );
    }

    else {
        return (
            <>
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement des offres en cours.....' />
                </div>
            </>
        );
    }
};

export default Contact;