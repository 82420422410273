import React, {useEffect, useState} from 'react';
import axios from 'axios';
import "../../sass/components/_defilement.scss";
import {ApiConfig} from '../Api/ApiConfig'
import Parser from 'html-react-parser';

const Defilement = () => {
    const [Data, setData] = useState([]);
    const [active, setActive] = useState(true);
    const API_PATH  = 'defilement_homes.json';

    const isDesactive = () => setActive(false);

    const fetchData = () => {
        const Data = `${ApiConfig.baseURL+API_PATH}`;
        const getData = axios.get(Data)

        axios.all([getData]).then(
            // eslint-disable-next-line
            axios.spread((... allData) => {
                const getData = allData[0].data[0]
                setData(getData)
            })
        )
    }

    const Defile = () => 
        Data.active === true ? 
            <>
                    <button onClick={isDesactive} className="fr-link--close fr-link close-defilement-close">Fermer l'info</button>
                    <section className="defilement" style={{backgroundColor : "var(--background-action-high-blue-france"}}>
                        <span style={{color : "var(--text-inverted-blue-france)"}}>{Parser(Data.texte)}</span>
                    </section>
            </>
        : null
    

    useEffect(() => {
        fetchData()
    }, [])
    
    return ( 
        <>
            {active ? <Defile/> : null}
        </>
    );
}
 
export default Defilement;