import React, { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import { Helmet } from 'react-helmet'
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import Parser from 'html-react-parser';
import Scroll from '../Util/Scroll';
import Footer from '../99_Footer/Footer';
import { Facebook } from 'react-content-loader'
import { Fade, Zoom } from "react-awesome-reveal";

const Metiers = (props) => {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const [dataFilter1, setDataFilter1] = useState([]);
    const [filter1, setFilter1] = useState('');
    const API_FILTER1_PATH = "accesses.json?order%5Btitle%5D=asc";
    const [dataFilter2, setDataFilter2] = useState([]);
    const [filter2, setFilter2] = useState('');
    const API_FILTER2_PATH = "mission_categories.json?order%5Btitle%5D=asc";
    const [dataFilter3, setDataFilter3] = useState([]);
    const [filter3, setFilter3] = useState('');
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const API_FILTER3_PATH = "study_levels.json?order%5Btitle%5D=asc";
    const API_PATH = "metiers.json?order%5Bintituler%5D=asc";
    const TITLE = 'Civils de la Défense - Métiers';

    const location = useLocation();


    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_FILTER1_PATH}`)
            .then(res => {
                setDataFilter1(res);
            }).catch(err => {
                setError(err);
            }
            );
    }, []);

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_FILTER2_PATH}`)
            .then(res => {
                setDataFilter2(res);
            }).catch(err => {
                setError(err);
            }
            );
    }, []);

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_FILTER3_PATH}`)
            .then(res => {
                setDataFilter3(res);
            }).catch(err => {
                setError(err);
            }
            );
    }, []);

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setFilteredData(res);
                if (data && dataFilter1 && dataFilter2 && dataFilter3) {
                    setLoad(true);
                }
            }).catch(err => {
                setError(err);
            }
            );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const missionCategory = location?.state?.missionCategory;

        if(missionCategory){
            setFilter2(missionCategory.title);
        }
    }, [location]);


    const filterData = (search) => {
        setSearch(search);
        setFilteredData(
            data.filter((metiers) =>
                metiers.intituler.toLowerCase().includes(search.toLowerCase())
            )
        )
    }

    useEffect(() => {
        if (search === '') {
            setFilteredData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    if (load && !error) {
        return (
            <>
                <Scroll />
                <Helmet><title>{TITLE}</title></Helmet>
                <main id="main metiers_all">
                    <p className="ta-c fr-pt-5w">Plus de 3000 métiers</p>
                    <h1 className="ta-c fr-mb-5w">Rechercher un métier</h1>
                    <div className="fr-mb-6v fr-container">
                        {/* Recherche */}
                        <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center fr-mb-10v" id="header-search" role="search">
                            <div className="fr-select-group fr-col-12 fr-col-sm-8 fr-col-md-6 fr-col-lg-4 fr-col-xl-4">
                                <label className="fr-label" htmlFor="select1">
                                    Choix de la voie d'accès
                                </label>
                                <select onChange={e => setFilter1(e.target.value)} title="Choix de la voie d'accès" id="select1" className="fr-select">
                                    <option value="">Toutes voies d'accès</option>
                                    {dataFilter1.map((filter, index) => (
                                        <option key={index} value={filter.title}>{filter.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="fr-select-group fr-col-12 fr-col-sm-8 fr-col-md-6 fr-col-lg-4 fr-col-xl-4">
                                <label className="fr-label" htmlFor="select2">
                                    Choix de la catégories de mission
                                </label>
                                <select defaultValue={filter2 === '' ? 'Toutes catégories de mission' : filter2 } onChange={e => setFilter2(e.target.value)} title="Choix de la catégories de mission" id="select2" className="fr-select">
                                    <option value="">Toutes catégories de mission</option>
                                    {dataFilter2.map((filter, index) => (
                                        <option key={index} value={filter.title}>{filter.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="fr-select-group fr-col-12 fr-col-sm-8 fr-col-md-6 fr-col-lg-4 fr-col-xl-4">
                                <label className="fr-label" htmlFor="select3">
                                    Choix du niveau d'études
                                </label>
                                <select onChange={e => setFilter3(e.target.value)} title="Choix du niveau d'études" id="select3" className="fr-select">
                                    <option value="">Tous niveaux d'études</option>
                                    {dataFilter3.map((filter, index) => (
                                        <option key={index} value={filter.title}>{filter.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="fr-alert fr-alert--info fr-alert--sm fr-mb-6v">
                                <p>Information : filtrez et naviguez entre les fiches.</p>
                            </div>
                            <div className="fr-search-bar fr-select-group fr-col-12 fr-col-sm-10 fr-col-md-8 fr-col-lg-8 fr-col-xl-8">
                                <label className="fr-label" htmlFor="recherche">
                                    Rechercher un métier
                                </label>
                                <input
                                    className="fr-input"
                                    placeholder="Recherche textuelle - Exemple : Infirmier"
                                    type="search"
                                    id="recherche"
                                    name="recherche"
                                    onChange={(e) => filterData(e.target.value)}
                                />
                                <button className="fr-btn" title="Rechercher">Rechercher</button>
                            </div>
                        </div>
                        {/* Data Filter */}
                        <Fade cascade bottom delay={100}>
                            <div className="fr-grid-row--center">
                                {
                                    filteredData && filteredData
                                    .filter(item => filter1 === '' || item.accesses.some(array => array.title === filter1))
                                    .filter(item => filter2 === '' || item.missionCategory.some(array => array.title === filter2))
                                    .filter(item => filter3 === '' || item.studyLevels.some(array => array.title === filter3))
                                    .map((metier, index) => (
                                        metier.isDraft ?
                                            <Zoom cascade bottom delay={30} className="fr-mb-3w" key={index}>
                                                <div className="fr-card fr-enlarge-link fr-card--horizontal fr-mb-3w" key={metier.id}>
                                                    <div className="fr-card__body">
                                                        <div class="fr-card__content">
                                                            <h3 className="fr-card__title fr-mb-2w">
                                                                <Link to={`/metier/${metier.id}`} className="btn-unfilled" title={`${"Ouverture de la fiche métier : " + metier.intituler}`}>{Parser(metier.intituler.toUpperCase())}</Link>
                                                            </h3>
                                                            <p className="fr-card__desc">{Parser(metier.resumer)}</p>
                                                            <div className="fr-card__start fr-mt-2w">
                                                                <p style={{height:"5rem"}}>
                                                                    {metier.accesses.map((element, index) => {
                                                                        return (<span key={index} className="fr-badge fr-badge--green-menthe fr-mx-1w fr-mb-2w">{element.title}</span>)
                                                                    })}
                                                                    {metier.missionCategory.map((element, index) => {
                                                                        return (<span key={index} className="fr-badge fr-badge--brown-caramel fr-mx-1w fr-mb-2w">{element.title}</span>)
                                                                    })}
                                                                    {metier.studyLevels.map((element, index) => {
                                                                        return (<span key={index} className="fr-badge fr-badge--purple-glycine fr-mx-1w fr-mb-2w">{element.title}</span>)
                                                                    })}        
                                                                </p>
                                                                {/* <p class="fr-card__detail fr-icon-warning-fill">détail (optionnel)</p> */}
                                                            </div>
                                                            <div className="fr-card__end">
                                                                <p className="fr-card__detail fr-icon-information-fill">{metier.domaine}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fr-card__header">
                                                        <div className="fr-card__img">
                                                            {metier.media.contentUrl.endsWith('.webm') ?
                                                                <video src={`${ApiConfig.baseURL_IMG + metier.media.contentUrl}`} autoPlay muted loop className="fr-responsive-video" /> :
                                                                <img src={`${ApiConfig.baseURL_IMG + metier.media.contentUrl}`} alt={metier.intituler} className="fr-responsive-img " />
                                                            }
                                                            <div className="filter-f"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ Zoom>    
                                            : null
                                        )
                                    )                                                
                                }
                                {
                                    filteredData && ((filteredData).filter(item => filter1 === '' || item.accesses.some(array => array.title === filter1))
                                        .filter(item => filter2 === '' || item.missionCategory.some(array => array.title === filter2))
                                        .filter(item => filter3 === '' || item.studyLevels.some(array => array.title === filter3)).length === 0) && (
                                        <div className="fr-mb-6v fr-container">
                                            <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center" >
                                                <div className="fr-alert fr-alert--error fr-alert--sm">
                                                    <p >Erreur: la fiche métier recherchée n'existe pas ou est en cours de rédaction par nos équipes ....</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </Fade>
                    </div>
                </main>
                <Footer />
            </>
        );
    }

    else {
        return (
            <>
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement des offres en cours.....' />
                </div>
            </>
        );
    }
};

export default Metiers;