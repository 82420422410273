// C3NP-PROD
export const ApiConfig = ({
base: 'https://www.civils.defense.gouv.fr/',
baseURL: 'https://www.civils.defense.gouv.fr/api/public/api/',
baseURL_IMG: 'https://www.civils.defense.gouv.fr/api/public',
api_contractuels: 'https://contractuels.civils.defense.gouv.fr/',
api_admissio: 'https://admissio.defense.gouv.fr/',
api_stages: 'https://www.stages.defense.gouv.fr/',
api_pep: 'https://place-emploi-public.gouv.fr/nos-offres/filtres/organisme/5/',
num_version: '2.1.1.0',
date_version: '26 août 2024'
});