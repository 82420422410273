export function Translate(data) {
    switch (data) {
        case "YEARS":
            return('ans');
        case "YEAR":
            return('ans'); // toutes les données sont au singulier alors que...
        case "MONTHS":
            return('mois');
        case "MONTH":
            return('mois');
        case "WEEKS":
            return('semaines');
        case "WEEK":
            return('semaine');
        case "DAYS":
            return('jours');
        case "DAY":
            return('jour');
        default:
            return('indeterminé');
    }
}