export function TileUnderline(data) {
    switch (data) {
        case "CDD":
            return('recrutement4');
        case "cdd":
            return('recrutement4');
        case "CDI":
            return('recrutement4');
        case "cdi":
            return('recrutement4');
        case "STG":
            return('recrutement1');
        case "CAJ":
            return('recrutement1');
        case "APP":
            return('recrutement1');
        case "PEP":
            return('recrutement3');
        default:
            return('rb');
    }
}