import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'moment/locale/fr';
import Footer from '../99_Footer/Footer';
import { Helmet } from 'react-helmet'
import Scroll from '../Util/Scroll';
import Moment from 'react-moment';
import { now } from 'moment';
import { Facebook } from 'react-content-loader'
import {EndpointConfig} from "./EndpointConfig";

const ApiAdmissio = () => {

    const [DataADMISSIO, setDataADMISSIO] = useState([]);
    const API_PATH_ADMISSIO = "admissio/v1/api/calendriers?autoriteExamenId=civil";
    const ApiADMISSIO = `${EndpointConfig.api_admissio + API_PATH_ADMISSIO}`;
    const TITLE = 'Civils de la Défense - Concours Civils Admissio';
    // Gestion du loader, par défaut desactivé
    const [load, setLoad] = useState(false);


    const fetchData = () => {
        const DataADMISSIO = `${ApiADMISSIO}`;
        const getDataADMISSIO = axios.get(DataADMISSIO)

        axios.all([getDataADMISSIO.catch(err => {
            SetErrorDataAdmissio();
            // Je change l'état du chargement
            setLoad(true)
            console.log("Erreur API ADMISSIO : ", err)
        })
        ])
            .then(
                // eslint-disable-next-line
                axios.spread((...allData) => {
                    if(allData[0].data.calendriers){
                        // Admissio API
                        const getDataADMISSIO = allData[0].data.calendriers
                        setDataADMISSIO(getDataADMISSIO)
                    }
                    else{
                        SetErrorDataAdmissio();
                    }
                    // Je change l'état du chargement
                    setLoad(true)
                })
            )
            .catch(e => {
                console.log(new Error("Asynchronous error"));
            })
    }

    // Mis en forme de la donnée en erreur API ADMISSIO
    const SetErrorDataAdmissio = () => {
        setDataADMISSIO([{
            id: 'Ereur technique avec le site "' + EndpointConfig.api_admissio + '"',
            libelleLong: 'Ereur technique avec le site "' + EndpointConfig.api_admissio + '", suivez le lien pour visiter le site.',
            url: EndpointConfig.api_admissio,
            calendrier:
            {
                "debutCandidature": "2099-12-31T00:00:00",
                "finCandidature": "2099-12-31T00:00:00",
                "finDepotDossier": "2099-12-31T00:00:00",
                "premieresEpreuves": "2099-12-31T00:00:00"
            }
        }]);
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!load) {
        return (
            <div>
                <div className="fr-my-6v fr-container">
                    <center>
                        <h1 className="ta-c">Le recrutement qui vous correspond est en cours de chargement...</h1>
                        <React.Fragment><h4>Le chargement des recrutements peut prendre quelques instants (soyez patient...)</h4></React.Fragment>
                    </center>
                    <div className="fr-mb-6v fr-container">
                        <Facebook title='Chargement des offres en cours.....' />
                    </div>
                </div>
            </div>);
    }

    return (
        <>
            <Scroll />
            <Helmet><title>{TITLE}</title></Helmet>
            <section className="fr-mb-6v fr-container">
                <div>
                    <p className="ta-c fr-pt-5w">Concours et Examens Professionnels</p>
                    <h1 className="ta-c fr-mb-5w">Personnels Civils de la Défense</h1>
                    <div className="fr-callout fr-fi-eye-off-line fr-my-7w">
                        <h2 className="fr-callout__title">Information utilisateurs</h2>
                        <p className="fr-callout__text" style={{ fontSize: 1 + 'rem' }}>La page affiche les recrutements sous forme de tuile cliquable.<br></br>
                            Si aucune tuile n'est affichée, cela signifie qu'il n'y a pas de recrutement ouvert à l'inscription.</p>
                    </div>
                </div>

                <div>
                    {DataADMISSIO.map((GetData, index) =>
                        (Date.parse(GetData.calendrier.finCandidature) > now()) ?
                            <section className="fr-pb-3w" key={index}>
                                <div className="fr-card fr-card--horizontal fr-enlarge-link rb">
                                    <div className="fr-card__body">
                                        <h2 className="fr-card__title fr-pb-3w">
                                            <a
                                                href={GetData.url}
                                                title={"Candidater pour " + GetData.libelle ? GetData.libelle : GetData.id + " - [Ouvrir dans une nouvelle fenêtre]"}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="hvr-buzz-out cta-filled">
                                                {GetData.libelleLong}
                                            </a>
                                        </h2>
                                        <p className="fr-card__detail ta-l fr-pt-3w w100 rinfo">{GetData.libelle ? GetData.libelle : GetData.id}</p>
                                        <p className="fr-card__desc ta-l">Date de début des candidatures : <Moment locale="fr" format="LLLL [(Heure de Paris)]">{GetData.calendrier.debutCandidature}</Moment></p>
                                        <p className="fr-card__desc ta-l">Date de fin des candidatures : <Moment locale="fr" format="LLLL [(Heure de Paris)]">{GetData.calendrier.finCandidature}</Moment></p>
                                    </div>
                                </div>
                            </section>
                            : <></>
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ApiAdmissio;