import Red from '../../img/icon/recrutement/account-red.svg';
import Blue from '../../img/icon/recrutement/account-blue.svg';
import Orange from '../../img/icon/recrutement/account-orange.svg';
import Green from '../../img/icon/recrutement/account-green.svg';
import Purple from '../../img/icon/recrutement/account-purple.svg';

export function Type(data) {
    switch (data) {
        case "CDD":
            return(Red);
        case "cdd":
            return(Red);
        case "CDI":
            return(Blue);
        case "cdi":
            return(Blue);
        case "STG":
            return(Orange);
        case "CAJ":
            return(Green);
        case "APP":
            return(Purple);
        case "PEP":
            return(Red);
        default:
            return(Red);
    }
}