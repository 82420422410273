export function NiveauEtude(data) {
    switch (data) {
        case 2:
            return("Sans diplôme");
        case 3:
            return("CAP, BEP");
        case 4:
            return("Bac");
        case 5:
            return("Bac +2");
        case 6:
            return("Bac +3");
        case 7:
            return("Bac +5");
        case 8:
            return("Doctorat");
        default:
            return data;
    }
}