import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { NiveauEtude } from '../Util/NiveauEtude'
import { Type } from '../Util/Type'
import axios from 'axios';
import { ApiConfig } from '../Api/ApiConfig'
import '../../sass/components/_recrutement.scss';
import Pin from '../../img/icon/recrutement/map-pin-line.svg';
import Pin2 from '../../img/icon/recrutement/map-pin-lineWhite.svg';
import Info from '../../img/icon/recrutement/information-line.svg';
import Info2 from '../../img/icon/recrutement/information-lineWhite.svg';
import Footer from "../99_Footer/Footer";
import { Link } from "react-router-dom";
import Scroll from "../Util/Scroll";
import { Zoom } from "react-awesome-reveal";
import { TileUnderline } from '../Util/TileUnderline';
import { Translate } from '../Util/Translate';
// import slugify from 'react-slugify';
import { Facebook } from 'react-content-loader'
import 'moment/locale/fr';

import ReactPaginate from 'react-paginate';
import '../../sass/components/_pagination.scss'
import {EndpointConfig} from "../Api/EndpointConfig";

const Recrutement = () => {
    // Data 1
    const [Data1, setData1] = useState([]);
    // Data 2
    const [Data2, setData2] = useState([]);
    // Data All
    const [data] = useState([]);

    const [search, setSearch] = useState("Fake");
    const [filteredData, setFilteredData] = useState([]);
    const TITLE = 'Civils de la Défense - Recrutement'

    // Gestion du loader, par défaut desactivé
    const [load, setLoad] = useState(false);

    // Attribution des Call
    const API_PATH_CONTRAC = 'offresdemploi.json?no_pagination=true'
    const Api_01 = EndpointConfig.api_contractuels + API_PATH_CONTRAC;
    const API_PATH_STAGE = 'api/offers';
    const Api_02 = EndpointConfig.api_stages + API_PATH_STAGE;

    // initialisations des longueurs de tableau
    let nbreContract = 0;
    let nbreStages = 0;

    //------------------------------------------------------------//
    // Call API
    // Control all possible erreor switch each APis
    //-----------------------------------------------------------//

    // Call des 2
    const fetchData = () => {
        // State Data1 + Call
        const Data1 = `${Api_01}`;
        const getData1 = axios.get(Data1)

        // State Data2 + Call
        const Data2 = `${Api_02}`;
        const getData2 = axios.get(Data2)

        // Exécution de plusieurs requêtes simultanées
        axios.all([getData1.catch(err => {
            console.log('Erreur sur API 1, on va juste recall 2');
            console.log("Erreurs API 1 : ", err)
            fecthTwo()
        }),
        getData2.catch(err => {
            console.log('Erreur sur API 2, on va juste recall 1');
            console.log("Erreurs API 2 : ", err)
            fecthOne()
        })
        ])
            .then(
                // eslint-disable-next-line
                axios.spread((...allData) => {
                    // Je change l'état du chargement
                    setLoad(true)
                    // Je selectionne l'entrypoint de l'api
                    const getData1 = allData[0].data
                    const getData2 = allData[1].data.result
                    // On affecte la valeur obtenu au state
                    setData1(getData1)
                    TranformData2(getData2, setData2)
                })
            )
            .catch(e => {
                console.log(new Error("Asynchronous error"));
            });
    }

    // Call juste de 1 si erreur 2
    const fecthOne = () => {
        //Set Data2 with error values
        SetErrorData2()

        // Exécution de la requête restante
        axios.get(`${Api_01}`)
            .then(res => {
                setData1(res.data)
                setSearch('')
            })
            .catch(e => {
                console.log(new Error("Asynchronous error"));
            });
    }

    // Call juste de 2 si erreur 1
    const fecthTwo = () => {
        //Set Data1 with error values
        SetErrorData1()

        // Exécution de la requête restante
        axios.get(`${Api_02}`)
            .then(res => {
                TranformData2(res.data.result, setData2)
                setSearch('')
            })
            .catch(e => {
                console.log(new Error("Asynchronous error"));
            });
    }

    // Mis en forme des data2 pour les adapter au format data1
    const TranformData2 = (data, setData) => {
        setData(data.map(res => {
            return {
                libelle: res.title,
                type: res.reference.split("/")[0],
                famille: res.businessDomains[0].name,
                niveauEtude: res.studyLevels[0].name,
                ville: res.city,
                departement: res.postalCode,
                lien: EndpointConfig.api_stages + 'offer/' + slugify(res.title) + '-' + res.offerId,
                duree: res.duration + " " + Translate(res.durationType)
            }
        }))
        setSearch('')
    }

    //------------------------------------------------------------//
    // Fonction de transformation en slug
    //-----------------------------------------------------------//
    function slugify(string) {
        return string.sansAccent()
            .toString()
            .toLowerCase()
            .replace(" - ", "-")
            .replace("/ ", "-")
            .replace(/'/g, "-")
            .replace(/\s/g, "-")
            .replace("/", "-")
            .replace(/,/g, "")
            .replace('--', '-')
            .replace('\u2019', '-') //apostrophe mal gérée(prime) par copier-coller de Word
    }

    // eslint-disable-next-line
    String.prototype.sansAccent = function () {
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['a', 'a', 'e', 'e', 'i', 'i', 'o', 'o', 'u', 'u', 'n', 'n', 'c', 'c'];

        var str = this;
        for (var i = 0; i < accent.length; i++) {
            str = str.replace(accent[i], noaccent[i]);
        }

        return str;
    }

    //------------------------------------------------------------//
    // Tableaux d'erreur
    //-----------------------------------------------------------//
    // Mis en forme de la donnée en erreur API 1
    const SetErrorData1 = () => {
        setData1([{
            libelle: "La connexion avec le site « Contracuels de la défense » ne nous permet pas encore d’afficher les offres individualisées dans cette rubrique mais le lien vous dirige vers l’ensemble des offres du ministère.",
            type: 'CDI',
            famille: 'API en erreur / en travaux - Suivez le lien pour accéder au site et consulter les offres',
            niveauEtude: 'Sans / Tous diplômes',
            ville: 'Toutes villes',
            departement: 'Tous départements',
            lien: EndpointConfig.api_contractuels,
            duree: '0 mois'
        }]);
    }

    // Mis en forme de la donnée en erreur API 2
    const SetErrorData2 = () => {
        setData2([{
            libelle: "La connexion avec le site « Première expérience défense » ne nous permet pas encore d’afficher les offres individualisées dans cette rubrique mais le lien vous dirige vers l’ensemble des offres du ministère.",
            type: 'STG',
            famille: 'API en erreur / en travaux - Suivez le lien pour accéder au site et consulter les offres',
            niveauEtude: 'Sans / Tous diplômes',
            ville: 'Toutes villes',
            departement: 'Tous départements',
            lien: EndpointConfig.api_stages,
            duree: '0 mois'
        }]);
    }

    //------------------------------------------------------------//
    // Merge toutes les datas dans le même tableau
    //-----------------------------------------------------------//
    const AddOtherRestData = () => {
        // on vide data
        data.splice(0);

        // on vide le premier JSON dans data
        nbreContract = Data1.length
        if (nbreContract === 1) { nbreContract = 0 } //cas de la donnée en erreur
        var i = 0;
        while (i < Data1.length) {
            data.splice(i, 0, Data1[i]);
            i++;
        }

        // on vide le deuxième JSON dans data
        nbreStages = Data2.length
        if (nbreStages === 1) { nbreStages = 0 } //cas de la donnée en erreur
        i = 0;
        while (i < Data2.length) {
            data.splice(2 * i + 1, 0, Data2[i]);
            i++;
        }

        // on vide le troisième JSON dans data
        data.splice(0, 0, {
            libelle: "La connexion avec le site « Place de l‘emploi public » ne nous permet pas encore d’afficher les offres individualisées dans cette rubrique mais le lien vous dirige vers l’ensemble des offres du ministère.",
            type: 'PEP',
            famille: 'Suivez le lien pour accéder au site et consulter les offres',
            niveauEtude: 'Sans / Tous diplômes',
            ville: 'Toutes villes',
            departement: 'Tous départements',
            lien: EndpointConfig.api_pep,
            duree: '0 mois'
        });
    }

    //------------------------------------------------------------//
    // Preparer les data
    //-----------------------------------------------------------//
    const DataDetail = (props) => {

        const { libelle, type, famille, niveauEtude, ville, departement, lien, duree } = props;

        return (
            <Zoom cascade bottom delay={50}>
                <section className="fr-pb-3w" id="link-recrutementall">
                    <div className={"fr-card fr-card--horizontal fr-enlarge-link " + TileUnderline(type)}>
                        <div className="fr-card__body">
                            <h3 className="fr-card__title fr-pt-1w">
                                <a href={lien} title={"Postuler pour " + libelle.capitalize() + " - [Ouvrir dans une nouvelle fenêtre]"} target="_blank" rel="noopener noreferrer" className="hvr-buzz-out cta-filled">{(libelle.capitalize())}</a>
                            </h3>

                            <p className="fr-card__detail ta-l fr-pt-3w w100 rinfo">
                                <img src={Type(type)} alt="icon type" className="icon-ds-c" />
                                {type}{duree ? ' / ' + duree : ''}
                                <img src={Pin} alt="localisation" className="icon-ds-c icon-ds-p icon1" />
                                <img src={Pin2} alt="localisation" className="icon-ds-c icon-ds-p icon2" />

                                {ville ? ville : departement}
                                <img src={Info} alt="localisation" className="icon-ds-c icon-ds-p icon1" />
                                <img src={Info2} alt="localisation" className="icon-ds-c icon-ds-p icon2" />
                                {NiveauEtude(niveauEtude)}
                            </p>

                            <p className="fr-card__desc ta-l fr-pt-1w">{famille.capitalize()}</p>
                        </div>
                    </div>
                </section>
            </Zoom>
        );
    };

    const Bandeautiles = (props) => {

        return (
            <div className="fr-mb-6v fr-container">
                <div className="fr-grid-row fr-grid-row--gutters fr-pb-5w fr-pt-5w">
                    <div className="fr-col-12 fr-col-md-3">
                        <div className="fr-card fr-enlarge-link">
                            <div className="fr-card__body recrutement1">
                                <p className="fr-card__detail ta-l">Première expérience Défense</p>
                                <h2 className="fr-card__title">
                                    <a href={EndpointConfig.api_stages} rel="noopener noreferrer" target="_blank" className="cta-filled" title="Recrutement : stages.defense.gouv.fr - [Ouvrir dans une nouvelle fenêtre]">Stage, Apprentissage<br></br>Contrat armées-jeunesse</a>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="fr-col-12 fr-col-md-3">
                        <div className="fr-card fr-enlarge-link">
                            <div className="fr-card__body recrutement2">
                                <p className="fr-card__detail ta-l">Concours</p>
                                <h2 className="fr-card__title">
                                    <Link to="/concours" className="cta-filled" title="Recrutement : page concours">Concours interne, externe et examens professionnels</Link>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="fr-col-12 fr-col-md-3">
                        <div className="fr-card fr-enlarge-link">
                            <div className="fr-card__body recrutement3">
                                <p className="fr-card__detail ta-l">Fonctionnaire</p>
                                <h2 className="fr-card__title">
                                    <a href={EndpointConfig.api_pep} rel="noopener noreferrer" target="_blank" className="cta-filled" title="Recrutement : place-emploi-public.gouv.fr - [Ouvrir dans une nouvelle fenêtre]">Offres d'emploi de la fonction publique</a>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="fr-col-12 fr-col-md-3">
                        <div className="fr-card fr-enlarge-link">
                            <div className="fr-card__body recrutement4">
                                <p className="fr-card__detail ta-l">CDD / CDI</p>
                                <h2 className="fr-card__title">
                                    <a href={EndpointConfig.api_contractuels} rel="noopener noreferrer" target="_blank" className="cta-filled" title="Recrutement : contractuels.civils.defense.gouv.fr - [Ouvrir dans une nouvelle fenêtre]">Plateforme de recrutement du personnel civil contractuel</a>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    //------------------------------------------------------------//
    // Call datas et aggrege tout
    //-----------------------------------------------------------//
    // https://fr.reactjs.org/docs/hooks-effect.html
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (load) {
        AddOtherRestData()
    }

    //------------------------------------------------------------//
    // Filtrer les data à partir du libelle
    //-----------------------------------------------------------//
    useEffect(() => {
        setFilteredData(
            data.filter((data) =>
                data.libelle.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, data]);

    //------------------------------------------------------------//
    // Capitalise the names
    //-----------------------------------------------------------//
    // eslint-disable-next-line
    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    //------------------------------------------------------------//
    // Pagination
    //-----------------------------------------------------------//
    const [pageNumber, setpageNumber] = useState(0);
    const recrutementsPerPage = 20;
    const pagesVisited = pageNumber * recrutementsPerPage;
    const pageCount = Math.ceil(filteredData.length / recrutementsPerPage);

    const displayRecruits = filteredData
        .slice(pagesVisited, pagesVisited + recrutementsPerPage)
        .map((data, idx) => (
            <DataDetail key={idx} {...data} />
        ));

    const changePage = ({ selected }) => {
        setpageNumber(selected)
        document.getElementById("offres").scrollIntoView();
    };

    //------------------------------------------------------------//
    // Return message when loading
    //-----------------------------------------------------------//

    if (!load) {
        return (
            <div>
                <aside style={{ backgroundImage: `url(${ApiConfig.baseURL_IMG}/media/5f81d33e8bc10_backgroud-civilsdeladefense.jpg)` }} alt="Background Civils de la Défense" className="banner-filter">
                    <div className="filter"></div>
                    <h1 style={{ backgroundColor: '#000091', padding: '1rem' }} className="ta-c">L’offre qui vous correspond est en cours de chargement...</h1>
                </aside>
                <div className="fr-mb-6v fr-container">
                    <Bandeautiles />
                    <center>
                        <React.Fragment><h4>Le chargement des offres peut prendre quelques instants (soyez patient...)</h4></React.Fragment>
                    </center>
                    <div className="fr-mb-6v fr-container">
                        <Facebook title='Chargement des offres en cours.....' />
                    </div>
                </div>
            </div>);
    }

    return (
        <React.Fragment>
            <Helmet><title>{TITLE}</title></Helmet>
            <main id="recrutement-offre main" role="main">
                <Scroll />

                <aside style={{ backgroundImage: `url(${ApiConfig.baseURL_IMG}/media/5f81d33e8bc10_backgroud-civilsdeladefense.jpg)` }} alt="Background Civils de la Défense" className="banner-filter">
                    <div className="filter"></div>
                    <h1 style={{ backgroundColor: '#000091', padding: '1rem' }} className="ta-c">Trouvez l’offre qui vous correspond <br></br>
                        <b>{nbreContract + nbreStages}</b> postes disponibles
                    </h1>
                </aside>

                <div className="fr-mb-6v fr-container">
                    <div className="fr-grid-row fr-grid-row--gutters fr-pb-5w fr-pt-5w">
                        <div className="fr-col-12 fr-col-md-3">
                            <div className="fr-card fr-enlarge-link">
                                <div className="fr-card__body recrutement1">
                                    <p className="fr-card__detail ta-l">Première expérience Défense :</p>
                                    <span className="fr-badge fr-badge--green-menthe fr-mt-3w">{nbreStages} Stage / Apprentissage / Contrat-Armées-Jeunesse</span>
                                    <h2 className="fr-card__title">
                                        <a href={EndpointConfig.api_stages} rel="noopener noreferrer" target="_blank" className="cta-filled" title="Recrutement : stages.defense.gouv.fr - [Ouvrir dans une nouvelle fenêtre]">Stage, Apprentissage<br></br>Contrat armées-jeunesse</a>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="fr-col-12 fr-col-md-3">
                            <div className="fr-card fr-enlarge-link">
                                <div className="fr-card__body recrutement2">
                                    <p className="fr-card__detail ta-l">Concours</p>
                                    <h2 className="fr-card__title">
                                        <Link to="/concours" className="cta-filled" title="Recrutement : page concours">Concours interne, externe et examens professionnels</Link>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="fr-col-12 fr-col-md-3">
                            <div className="fr-card fr-enlarge-link">
                                <div className="fr-card__body recrutement3">
                                    <p className="fr-card__detail ta-l">Fonctionnaire</p>
                                    <h2 className="fr-card__title">
                                        <a href={EndpointConfig.api_pep} rel="noopener noreferrer" target="_blank" className="cta-filled" title="Recrutement : place-emploi-public.gouv.fr - [Ouvrir dans une nouvelle fenêtre]">Offres d'emploi de la fonction publique</a>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="fr-col-12 fr-col-md-3">
                            <div className="fr-card fr-enlarge-link">
                                <div className="fr-card__body recrutement4">
                                    <p className="fr-card__detail ta-l">CDD / CDI</p>
                                    <span className="fr-badge fr-badge--green-menthe fr-mt-3w">{nbreContract} CDD / CDI</span>
                                    <h2 className="fr-card__title">
                                        <a href={EndpointConfig.api_contractuels} rel="noopener noreferrer" target="_blank" className="cta-filled" title="Recrutement : contractuels.civils.defense.gouv.fr - [Ouvrir dans une nouvelle fenêtre]">Plateforme de recrutement du personnel civil contractuel</a>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="fr-mb-6v fr-container" id="offres">
                    <h2 className="ta-l fr-mb-5w">Rechercher une offre</h2>
                    <span>
                        {(search.length && filteredData.length === 1) ?
                            <p className="fr-mb-2w">
                                <b style={{ color: "#004494" }}>Une seule offre</b> correspond à votre recherche
                            </p> : null
                        }
                        {(search.length && filteredData.length > 1) ?
                            <p className="fr-mb-2w">
                                <b style={{ color: "#004494" }}>{filteredData.length} offres</b> correspondent à votre recherche
                            </p> : null
                        }
                        {search.length ?
                            null
                            : <React.Fragment></React.Fragment>
                        }
                    </span>

                    <div className="fr-search-bar fr-mb-10v" id="header-search" role="search">
                        <label className="fr-label" htmlFor="recherche">
                            Rechercher une offre
                        </label>
                        <input
                            className="fr-input"
                            name="recherche"
                            type="search"
                            placeholder="Rechercher par mot-clé dans nos offres…"
                            onChange={(e) => {
                                setSearch(e.target.value)
                                // setpageNumber(0)
                                var page1 = document.querySelector("nav.fr-pagination a[href='/page/1']")
                                var evObj = document.createEvent('Events');
                                evObj.initEvent('click', true, false);
                                if (page1) {
                                    page1.dispatchEvent(evObj)
                                }
                            }}
                        />
                        <button className="fr-btn" title="Rechercher">
                            Rechercher
                        </button>
                    </div>
                    {displayRecruits}
                    <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center">
                        <nav role="navigation" className="fr-pagination" aria-label="Pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Page suivante"
                                onPageChange={changePage}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                previousLabel="Page précédente"
                                renderOnZeroPageCount={null}
                                containerClassName={'fr-pagination__list'}
                                pageLinkClassName={'fr-pagination__link'}
                                previousLinkClassName={'fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg-label'}
                                nextLinkClassName={'fr-pagination__link fr-pagination__link--next fr-pagination__link--lg-label'}
                                breakLinkClassName={'fr-pagination__list dot'}
                                hrefBuilder={(page, pageCount, selected) =>
                                    page >= 1 && page <= pageCount ? `/page/${page}` : '#'
                                }
                                hrefAllControls
                            />
                        </nav>
                    </div>
                    {
                        filteredData.length
                            ? <p></p> :

                            <section className="recrutement-dcid">
                                <div className="flex conteneur" style={{ justifyContent: "center" }}>
                                    <div className="col-12"><p style={{ color: "#004494" }}><b>Aucune offre ne correspond à votre recherche</b></p></div>
                                </div>
                            </section>
                    }

                </section>
                <Footer />

            </main>
        </React.Fragment>
    );
}

export default Recrutement;