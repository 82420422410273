import {getData} from "./getData";
import {ApiConfig} from "./ApiConfig";

let endpointsObject = {};
let endpoints = [];

const API_PATH = 'endpoints.json';

await getData(`${ApiConfig.baseURL + API_PATH}`)
    .then(res => {
        endpoints = res;
    }).catch(err => {
        console.error(err)
    }
);

for(const endpoint of endpoints){
    endpointsObject[endpoint.titre] = endpoint.url;
}
export const EndpointConfig = endpointsObject;