import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';

import Home from '../01_Home/Home';
import Chiffres from '../02_Chiffres/Chiffres';
import CommentNousRejoindre from '../03_NousRejoindre/CommentNousRejoindre';
import SliderCarrousel from '../04_Slider/SliderCarrousel';
import MetiersHome from '../05_Metiers/Metiers_Home';
import Footer from '../99_Footer/Footer';
import Defilement from '../Util/Defilement';
import Deuil from '../Util/Deuil';
import Scroll from '../Util/Scroll';
import MissionCategoryCarrousel from "../04_Slider/MissionCategoryCarrousel";

class Main extends Component {

    render() {
        return (
            <>
                <main id="main" role="main">
                    <Scroll />
                    <Deuil />
                    <Home />
                    <Defilement />
                    <Chiffres />
                    <MissionCategoryCarrousel />
                    <CommentNousRejoindre />
                    <SliderCarrousel />
                    <MetiersHome />
                </main>
                <Footer />
            </>
        );
    }
}

export default Main