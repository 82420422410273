import React, { useState, useEffect } from 'react';
import { stack as Menu } from "react-burger-menu";
import { HashLink as Link } from 'react-router-hash-link';
import '../../sass/components/_burgermenu.scss';
import Logo from "../../img/logo/Logo_CivilsDeLaDefense.svg";
import LogoWhite from "../../img/logo/Logo_CivilsDeLaDefenseWhite.svg";
import MenuIcon from "../../img/icon/menu/burger.svg";
import MenuIconWhite from "../../img/icon/menu/burgerWhite.svg";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import "../../sass/components/_liens.scss";
import { getData } from '../Api/getData';
import { ApiConfig } from '../Api/ApiConfig';
import { isWithinInterval } from "date-fns";

const BurgerMenu = (areSlidersActives) => {
    const [schemeState, setschemeState] = useState(false);
    const SCHEME_ATTRIBUTE = 'data-fr-scheme';

    const [data, setData] = useState([]);
    const API_PATH = 'menu_actives.json';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res[0]);
            }).catch(err => {
                console.log(err);
            }
            );
    }, []);

    useEffect(() => {
        const getscheme = localStorage.getItem('scheme');
        if (getscheme === 'dark') {
            setschemeState(true);
        }
    }, []);

    useEffect(() => {
        if (schemeState) {
            localStorage.setItem('scheme', 'dark');
            document.body.classList.add('dark-mode');
            document.getElementsByTagName("html")[0].setAttribute(SCHEME_ATTRIBUTE, "dark");
        } else {
            localStorage.setItem('scheme', 'light');
            document.body.classList.remove('dark-mode');
            document.getElementsByTagName("html")[0].setAttribute(SCHEME_ATTRIBUTE, "light");
            return
        }
    }, [schemeState]);

    const NuitIcon = <span role="img" alt="nuit" aria-label="Nuit">🌙</span>;
    const JourIcon = <span role="img" alt="jour" aria-label="Jour">🔆</span>;

    return (
        <>
            <Menu right customBurgerIcon={<><img src={MenuIcon} alt="menu" id="menuburger1" /> <img src={MenuIconWhite} alt="menu" id="menuburger2" /></>}>
                <nav className="fr-nav" aria-label="Menu principal" id="menu-burger">

                    <img src={Logo} alt="Logo Civils de la Défense" className="fr-mb-5w logo1 burger-logo" />
                    <img src={LogoWhite} alt="Logo Civils de la Défense" className="fr-mb-5w logo2 burger-logo" />
                    <ul className="fr-links-group">
                        <li className="fr-link fr-fi-theme-fill fr-link--icon-left" aria-controls="fr-theme-modal">Thème du site
                            <Toggle
                                className="DarkToggle"
                                checked={schemeState}
                                onChange={e => setschemeState(e.target.checked)}
                                icons={{ checked: NuitIcon, unchecked: JourIcon }}
                                aria-label="Changer le thème du site"
                            />
                        </li>
                        <li><Link to="/" className="fr-link" title="Redirection sur la page : Accueil">Accueil</Link></li>
                        {
                            areSlidersActives ? <li><Link to="/#slider" className="fr-link" title="Redirection sur la page : Actualités">Actualités</Link></li>
                                : null
                        }
                        <li><Link to="/metiers" className="fr-link" title="Redirection sur la page : Nos métiers">Nos métiers</Link></li>
                        {data.isActiveArticleChiffres ?
                            <li><Link to="/chiffres" className="fr-link" title="Redirection sur la page : Chiffres clés">Chiffres clés</Link></li>
                            : null
                        }
                        {data.isActiveArticleInclusions ?
                            <li><Link to="/inclusion" className="fr-link" title="Redirection sur la page : Handicap">Handicap</Link></li>
                            : null
                        }
                        <li><Link to="/recrutement" className="fr-link" title="Redirection sur la page : Rechercher une offre">Rechercher une offre</Link></li>
                        <li><Link to="/admissio" className="fr-link" title="Redirection sur la page : Concours Civils">Concours Civils</Link></li>
                        {data.isActiveArticleConcours ?
                            <li><Link to="/concours" className="fr-link" title="Redirection sur la page : Concours Civils">Concours</Link></li>
                            : null
                        }
                        {data.isActiveArticleJury ?
                            <li><Link to="/jury" className="fr-link" title="Redirection sur la page : Jury">Jury</Link></li>
                            : null
                        }
                        {data.isActiveDga ?
                            <li><Link to="/dga" className="fr-link" title="Redirection sur la page : DGA">DGA</Link></li>
                            : null
                        }
                        {data.isActiveDgse ?
                            <li><Link to="/dgse" className="fr-link" title="Redirection sur la page : DGSE">DGSE</Link></li>
                            : null
                        }
                        {data.isActiveQuizz ?
                            <li><Link to="/quiz" className="fr-link" title="Redirection sur la page : Quiz">Quiz</Link></li>
                            : null
                        }
                        <li><Link to="/accessibilite" className="fr-link" title="Redirection sur la page : Accessibilité">Accessibilité</Link></li>
                        <li><Link to="/mentions-legales" className="fr-link" title="Redirection sur la page : Mentions légales">Mentions légales</Link></li>
                        <li><Link to="/nous-contacter" className="fr-link" title="Redirection sur la page : Nous contacter">Nous contacter</Link></li>
                    </ul>
                    <div className="fr-follow fr-mt-10w">
                        <div className="fr-container">
                            <div className="fr-grid-row">
                                <div className="fr-col-12">
                                    <div className="fr-follow__social">
                                        <h2 className="fr-h5">Suivez-nous
                                        <br/> sur les réseaux sociaux
                                        </h2>
                                        <ul class="fr-btns-group">
                                            <li>
                                                <a className="fr-btn--facebook fr-btn" title="Armees.Gouv - ouvre une nouvelle fenêtre" href="https://www.facebook.com/Armees.Gouv" target="_blank"  rel="noopener external noreferrer">
                                                    facebook
                                                </a>
                                            </li>
                                            <li>
                                                <a className="fr-btn--twitter-x fr-btn" title="@Armees_Gouv - ouvre une nouvelle fenêtre" href="https://twitter.com/armees_gouv" target="_blank" rel="noopener external noreferrer">
                                                    X (anciennement Twitter)
                                                </a>
                                            </li>
                                            <li>
                                                <a className="fr-btn--instagram fr-btn" title="Armees_gouv - ouvre une nouvelle fenêtre" href="https://www.instagram.com/armees_gouv/" target="_blank" rel="noopener external noreferrer">
                                                    instagram
                                                </a>
                                            </li>
                                            <li>
                                                <a className="fr-btn--linkedin fr-btn" title="Civils de la Défense - ouvre une nouvelle fenêtre" href="https://www.linkedin.com/company/civils-de-la-d%C3%A9fense/" target="_blank" rel="noopener external noreferrer">
                                                    linkedin
                                                </a>
                                            </li>
                                            <li>
                                                <a className="fr-btn--youtube fr-btn" title="Ministère des Armées - ouvre une nouvelle fenêtre" href="https://www.youtube.com/user/ministeredeladefense" target="_blank" rel="noopener external noreferrer">
                                                    youtube
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </Menu>
        </>
    );
};

export default BurgerMenu;