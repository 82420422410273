import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiConfig } from '../Api/ApiConfig'
import Parser from 'html-react-parser';

const Deuil = () => {
    const [Data, setData] = useState([]);
    const API_PATH = 'deuils.json';

    const fetchData = () => {
        const Data = `${ApiConfig.baseURL + API_PATH}`;
        const getData = axios.get(Data)

        axios.all([getData]).then(
            axios.spread((...allData) => {
                const getData = allData[0].data[0]
                setData(getData)
            })
        )
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (Data.active === true) {
        document.getElementsByTagName("html")[0].setAttribute('data-fr-mourning', "");
    } else {
        document.getElementsByTagName("html")[0].removeAttribute('data-fr-mourning');
    }

    if (Data.active === true) {
        return (
            <div className="fr-container fr-my-5w" id="Deuil">
                <div className="fr-callout fr-fi-information-fill">
                    <p className="fr-callout__title">
                        Deuil national
                    </p>
                    <p className="fr-callout__text">
                        {Parser(Data.texte)}
                    </p>
                </div>
            </div>
        );
    } else {
        return null;
    }

}

export default Deuil;
