import React, {useEffect, useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Dcid from "../../img/logo/Logo_CivilsDeLaDefense.svg";
import Dcid2 from "../../img/logo/Logo_CivilsDeLaDefenseWhite.svg";
import Terre from "../../img/logo/Terre.svg";
import Air from "../../img/logo/Air.svg";
import Marine from "../../img/logo/Marine.svg";
import Dga from "../../img/logo/Dga.svg";
import Dgse from "../../img/logo/Dgse.svg";

import MomentYears from "../../components/Util/MomentYears";
import { Fade } from "react-awesome-reveal";
import "../../sass/components/_liens.scss";
import {ApiConfig} from '../Api/ApiConfig';
import {getData} from "../Api/getData";

const Footer = () => {
    const [copyright, setCopyright] = useState(false);
    const [data, setData] = useState([]);
    const API_PATH = 'menu_actives.json';

    const onClick = () => {
        setCopyright(!copyright);
    };

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res[0]);
            }).catch(err => {
                console.log(err);
            }
        );
    }, []);

    return ( 
            <>
            <div className="fr-follow">
                <div className="fr-container">
                    <div className="fr-grid-row">
                        <div className="fr-col-12">
                            <div className="fr-follow__social">
                                <h2 className="fr-h5">Suivez-nous
                                <br/> sur les réseaux sociaux
                                </h2>
                                <ul class="fr-btns-group">
                                    <Fade top delay={50}>
                                        <li>
                                            <a className="fr-btn--facebook fr-btn" title="Armees.Gouv - ouvre une nouvelle fenêtre" href="https://www.facebook.com/Armees.Gouv" target="_blank"  rel="noopener external noreferrer">
                                                facebook
                                            </a>
                                        </li>
                                    </Fade>
                                    <Fade top delay={50}>
                                        <li>
                                            <a className="fr-btn--twitter-x fr-btn" title="@Armees_Gouv - ouvre une nouvelle fenêtre" href="https://twitter.com/armees_gouv" target="_blank" rel="noopener external noreferrer">
                                                X (anciennement Twitter)
                                            </a>
                                        </li>
                                    </Fade>
                                    <Fade top delay={50}>
                                        <li>
                                            <a className="fr-btn--instagram fr-btn" title="Armees_gouv - ouvre une nouvelle fenêtre" href="https://www.instagram.com/armees_gouv/" target="_blank" rel="noopener external noreferrer">
                                                instagram
                                            </a>
                                        </li>
                                    </Fade>
                                    <Fade top delay={50}>
                                        <li>
                                            <a className="fr-btn--linkedin fr-btn" title="Civils de la Défense - ouvre une nouvelle fenêtre" href="https://www.linkedin.com/company/civils-de-la-d%C3%A9fense/" target="_blank" rel="noopener external noreferrer">
                                                linkedin
                                            </a>
                                        </li>
                                    </Fade>
                                    <Fade top delay={50}>
                                        <li>
                                            <a className="fr-btn--youtube fr-btn" title="Ministère des Armées - ouvre une nouvelle fenêtre" href="https://www.youtube.com/user/ministeredeladefense" target="_blank" rel="noopener external noreferrer">
                                                youtube
                                            </a>
                                        </li>
                                    </Fade>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="fr-footer" id="footer">
                <div className="fr-container">
                    <div className="fr-footer__body ">
                    <div className="fr-header__brand fr-enlarge-link">
                                <div className="fr-header__brand-top">
                                    <div className="fr-header__logo">
                                        <p className="fr-logo">
                                            Ministère<br/>des Armées
                                        </p>
                                    </div>
                                    <div className="fr-header__operator">
                                    <Link to="/" title="Logo Civils de la Défense - Accueil">
                                        <img src={Dcid} className="fr-responsive-img logo1" alt="Logo Civils de la Défense"/>
                                        <img src={Dcid2} className="fr-responsive-img logo2" alt="Logo Civils de la Défense"/>
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        
                        <div className="fr-footer__content">
                            <p className="fr-footer__content-desc">Le Ministère des Armées dispose d’un site unique qui centralise, informe, vulgarise et distribue les informations concernant le recrutement des civils grâce au portail unique, intitulé « Civils de la Défense ».</p>
                            <ul className="fr-footer__content-list">
                                <li className="fr-footer__content-item">
                                    <a className="fr-footer__content-link" href="https://legifrance.gouv.fr" title="Legifrance - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank">legifrance.gouv.fr</a>
                                </li>
                                <li className="fr-footer__content-item">
                                    <a className="fr-footer__content-link" href="https://gouvernement.fr" title="Gouvernement - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank">gouvernement.fr</a>
                                </li>
                                <li className="fr-footer__content-item">
                                    <a className="fr-footer__content-link" href="https://service-public.fr" title="Service-public - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank">service-public.fr</a>
                                </li>
                                <li className="fr-footer__content-item">
                                    <a className="fr-footer__content-link" href="https://data.gouv.fr" title="Data.gouv.fr - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank">data.gouv.fr</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="fr-footer__partners">
                        <div className="fr-footer__partners-logos">
                            <div className="fr-footer__partners-main"></div>

                            <div className="fr-footer__partners-sub">
                                <ul>
                                    <li>
                                        <a className="fr-footer__partners-link" href="https://sengager.fr" title="Armée de Terre - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank" >
                                            <img src={Terre} className="fr-footer__logo fr-responsive-img" alt="Armée de Terre"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="fr-footer__partners-link" href="https://devenir-aviateur.fr" title="Armée de l'air et de l'espace - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank">
                                            <img src={Air} className="fr-footer__logo fr-responsive-img" alt="Armée de l'air et de l'espace"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="fr-footer__partners-link" href="https://etremarin.fr" title="Marine nationale - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank" >
                                            <img src={Marine} className="fr-footer__logo fr-responsive-img" alt="Marine nationale"/>
                                        </a>
                                    </li>
                                    {data.isActiveDga ?
                                    <li>
                                        <a className="fr-footer__partners-link" href="https://www.defense.gouv.fr/dga" title="Direction générale de l'Armement - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank">
                                            <img src={Dga} className="fr-footer__logo fr-responsive-img" alt="Direction générale de l'Armement"/>
                                        </a>
                                    </li> : null
                                    }
                                    {data.isActiveDgse ?
                                    <li>
                                        <a className="fr-footer__partners-link" href="https://dgse.gouv.fr" title="Direction générale de la Sécurité extérieure - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank">
                                            <img src={Dgse} className="fr-footer__logo fr-responsive-img" alt="Direction générale de la Sécurité extérieure"/>
                                        </a>
                                    </li> : null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="fr-footer__bottom">
                        <ul className="fr-footer__bottom-list fr-mb-4w">
                            <li className="fr-footer__bottom-item">
                                <Link to="/accessibilite" className="fr-footer__bottom-link" title="Redirection vers : Accessibilité">Accessibilité : partiellement - Déclaration d'accessibilité</Link>
                            </li>
                            <li className="fr-footer__bottom-item">
                                <Link to="/mentions-legales" className="fr-footer__bottom-link" title="Redirection vers : Mentions légales">Mentions légales</Link>
                            </li>

                            <li className="fr-footer__bottom-item">
                                <Link to="/plan-de-site#root" className="fr-footer__bottom-link" title="Redirection vers : Plan de site">Plan de site</Link>
                            </li>

                            <li className="fr-footer__bottom-item">
                                <Link to="/nous-contacter" className="fr-footer__bottom-link" title="Redirection vers : Nous contacter">Nous contacter</Link>
                            </li>

                            <li className="fr-footer__bottom-item">
                                <a href="https://www.defense.gouv.fr/" title="Defense.gouv.fr - [Ouvrir dans une nouvelle fenêtre]" rel="noopener noreferrer" target="_blank" className="fr-footer__bottom-link">Ministère des Armées - Tous droits réservés <MomentYears/></a>
                            </li>
                            <li className="fr-footer__bottom-item">
                                <button className="fr-footer__bottom-link" onClick={onClick}>Version</button>
                            </li>                           
                        </ul>
                    </div>
                </div>
            </footer>
            {copyright ? <div id="copyright" className="fr-follow">
                <div className="fr-container">
                    <div className="fr-grid-row">
                        <div className="fr-col-12 fr-col-md-8">
                            <div>
                                <p className="fr-text--sm">© 2020-<MomentYears/> Copyright : SMSIF-RH
                                    <br></br>(Développement &amp; Maintien en condition)</p>
                            </div>
                        </div>
                        <div className="fr-col-12 fr-col-md-3">
                            <div className="fr-follow__social">
                                <ul className="fr-text--sm">
                                    <li>
                                        Version : {ApiConfig.num_version}
                                    </li>
                                    <li>
                                        Version du {ApiConfig.date_version}
                                    </li>
                                    <li id="version_dsfr">
                                        Version DSFR : 1.11.2
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : 
            null}
            </>
    );
}

export default Footer;