import React from 'react';
import Article from "../layout/Article";
const API_PATH = 'dgses.json';
const TITLE = 'Civils de la Défense - Participer aux jurys'

const Dgse = () => {
    return (
        <div>
            <Article
                access_path={API_PATH}
                seo={TITLE}
            />

        </div>
    );
}

export default Dgse;