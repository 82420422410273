import React from 'react';
import Article from "../layout/Article";
const API_PATH = 'dgas.json';
const TITLE = 'Civils de la Défense - DGA'

const Dga = () => {
    return (
        <div>
            <Article
                access_path={API_PATH}
                seo={TITLE}
            />

        </div>
    );
}

export default Dga;