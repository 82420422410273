import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig';
import Parser from 'html-react-parser';
import CustomParser from '../Util/CustomParser';
import Footer from '../99_Footer/Footer';
import Scroll from '../Util/Scroll';
import { Facebook } from 'react-content-loader';
import "../../sass/components/_article.scss";

const Article = (props) => {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = props.access_path;
    const TITLE = props.seo

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true);
            }).catch(err => {
                setError(err);
                setLoad(true);
            }
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (load) {
        return (
            <>
                <Scroll />
                <Helmet><title>{TITLE}</title></Helmet>
                {error
                    ? <p>{error.message}</p>
                    : data.map((GetData, index) =>
                        <main id="main" role="main" key={index} className="fr-mb-10w">
                            {GetData.media.contentUrl.endsWith('.webm') ?
                                <div className="video-container">
                                    <video src={`${ApiConfig.baseURL_IMG}${GetData.media.contentUrl}`} autoPlay muted loop className="video" />
                                    <div className="filter"></div>
                                    <h1 style={{ backgroundColor: '#000091', padding: '1rem' }}>{GetData.titre}</h1>
                                </div> :
                                <aside style={{ backgroundImage: `url(${ApiConfig.baseURL_IMG + GetData.media.contentUrl})` }} className="banner-filter">
                                    <div className="filter"></div>
                                    <h1 style={{ backgroundColor: '#000091', padding: '1rem' }}>{GetData.titre}</h1>
                                </aside>
                            }
                            <div className="fr-container fr-mt-5w">
                                <CustomParser text={GetData.introduction} />
                                <h2 className="fr-mt-5w">{GetData.titreArticle1}</h2>
                                <CustomParser text={GetData.texteArticle1} />
                                <h2 className="fr-mt-5w">{GetData.titreArticle2}</h2>
                                <CustomParser text={GetData.texteArticle2} />
                                <h2 className="fr-mt-5w">{GetData.titreArticle3}</h2>
                                <CustomParser text={GetData.texteArticle3}/>
                            </div>
                            {GetData.cta && GetData.url && GetData.title ?
                                <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center" key={index}>
                                    <a href={Parser(GetData.url)} title={Parser(GetData.title) + "- [Ouvrir dans une nouvelle fenêtre]"} className="fr-btn fr-mt-5w" target="_blank" rel="noopener noreferrer">{Parser(GetData.cta)}</a>
                                </div>
                                : null}
                        </main>
                    )
                }
                <Footer />
            </>
        );
    }

    else {
        return (
            <>
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement en cours.....' />
                </div>
            </>
        );
    }
};

export default Article;