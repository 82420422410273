import React, { useState, useEffect, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import Scss
import "../../sass/components/_reset.scss";

// Import Js
import {ApiConfig} from '../Api/ApiConfig';
import ThreeDots from '../Loader/ThreeDots';
import Metiers from '../05_Metiers/Metiers';
import Header from '../00_Header/Header';
import Main from '../Pages/Main';
import Admissio from '../Api/ApiAdmissio';
import Recrutement from '../Pages/Recrutement';
import ArticlePersonnelCivil from '../06_Articles/Article_PersonnelCivil';
import ArticleJeunesse from '../06_Articles/Article_Jeunesse';
import ArticleEmplois from '../06_Articles/Article_Emplois';
import ArticleConcours from '../06_Articles/Article_Concours';
import ArticleChiffres from '../06_Articles/Article_Chiffres';
import ArticleInclusion from '../06_Articles/Article_Inclusion';
import ArticleFonctionnaire from '../06_Articles/Article_Fonctionnaire';
import ArticleJury from '../06_Articles/Article_Jury';
import Dga from '../06_Articles/Dga';
import Dgse from '../06_Articles/Dgse';
import Accessibilite from '../98_Accessibilite/Accessibilite';
import MentionsLegales from '../97_CGU/MentionsLegales';
import Contact from '../96_Contact/Contact';
import Bot from '../94_Bot/Bot';
import PlanDeSite from '../93_PlanDeSite/PlanDeSite';
import QuizHome from '../95_Quiz/Quiz_Home';
import Quiz from '../95_Quiz/Quiz';

const SingleMetier = lazy(() => import("../05_Metiers/SingleMetier"));
const NotFound = lazy(() => import("./NoMatch"));
const Rooter = () => {

    const [metiers, setMetiers] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const API_PATH  = 'metiers.json';

    useEffect(() => {
        fetch(`${ApiConfig.baseURL+API_PATH}`)
            .then(res => res.json()).then(res => {
                setMetiers(res)
            })
            .catch(e => {
                console.log(new Error("Asynchronous error"));
            });
        }, []);
        
    useEffect(() => {
        fetch(`${ApiConfig.baseURL + 'quizzes.json'}`)
            .then(res => res.json()).then(res => {
                setQuizzes(res)
            })
            .catch(e => {
                console.log(new Error("Asynchronous error"));
            });
    }, []);  

    return (
        <Suspense fallback={<ThreeDots/>}>    
            <Router>
                <Header/>
                {/* <Main /> */}
                <Routes>
                    <Route exact path="/" element={<Main />} />
                    <Route exact path="/recrutement" element={<Recrutement />} />
                    <Route exact path="/admissio" element={<Admissio />} />
                    <Route exact path="/metiers" element={<Metiers />} />
                    <Route path="/metier/:id" element={<SingleMetier data={metiers}/>} />
                    <Route exact path="/personnel-civil" element={<ArticlePersonnelCivil />} />
                    <Route exact path="/jeunesse" element={<ArticleJeunesse />} />
                    <Route exact path="/emplois" element={<ArticleEmplois />} />
                    <Route exact path="/concours" element={<ArticleConcours />} />
                    <Route exact path="/chiffres" element={<ArticleChiffres />} />
                    <Route exact path="/inclusion" element={<ArticleInclusion />} />
                    <Route exact path="/fonctionnaire" element={<ArticleFonctionnaire />} />
                    <Route exact path="/jury" element={<ArticleJury />} />
                    <Route exact path="/dga" element={<Dga />}/>
                    <Route exact path="/dgse" element={<Dgse />} />
                    <Route exact path="/accessibilite" element={<Accessibilite />} />
                    <Route exact path="/mentions-legales" element={<MentionsLegales />} />
                    <Route exact path="/nous-contacter" element={<Contact />} />
                    <Route exact path="/plan-de-site" element={<PlanDeSite />} />
                    <Route exact path="/quiz" element={<QuizHome />} />
                    <Route exact path="/quiz/:id" element={<Quiz data={quizzes}/>} />
                    <Route path="*" component={NotFound} />
                </Routes>
                <Bot/>
            </Router>   
        </Suspense>          
    )
};

export default Rooter