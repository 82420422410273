import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet'
import Footer from '../99_Footer/Footer';
import { getData } from '../Api/getData';
import { ApiConfig } from '../Api/ApiConfig';


const PlanDeSite = (props) => {
    const [data, setData] = useState([]);
    const API_PATH = 'menu_actives.json';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res[0]);
            }).catch(err => {
                console.log(err);
            }
            );
    }, []);
    const TITLE = "Civils de la Défense - Plan de site"

    return (
        <>
            <Helmet><title>{TITLE}</title></Helmet>
            <main role="main" id="main metiers_all">
                <p className="ta-c fr-pt-5w">Civils de la Défense</p>
                <h1 className="ta-c fr-mb-5w">Plan de site</h1>

                <nav className="fr-summary fr-container fr-mb-5w" role="navigation" aria-labelledby="fr-summary-title">
                    <div className="fr-summary__title" id="fr-summary-title">Civils de la Défense</div>
                    <ol className="fr-summary__list">
                        <li><Link to="/" title="Redirection vers : Accueil" className="fr-summary__link">Accueil</Link></li>
                        {data.isActiveArticleChiffres ?
                            <li><Link to="/chiffres" className="fr-summary__link" title="Redirection sur la page : Chiffres clés">Chiffres clés</Link></li>
                            : null
                        }
                        {data.isActiveArticleEmplois ?
                            <li><Link to="/emplois" className="fr-summary__link" title="Redirection sur la page : Emplois">Emplois</Link></li>
                            : null
                        }
                        {data.isActiveArticleFonctionnaire ?
                            <li><Link to="/fonctionnaire" className="fr-summary__link" title="Redirection sur la page : Fonctionnaire">Fonctionnaire</Link></li>
                            : null
                        }
                        {data.isActiveArticleInclusions ?
                            <li><Link to="/inclusion" className="fr-summary__link" title="Redirection sur la page : Handicap">Handicap</Link></li>
                            : null
                        }
                        {data.isActiveArticleJeunesse ?
                            <li><Link to="/jeunesse" className="fr-summary__link" title="Redirection sur la page : Jeunesse">Jeunesse</Link></li>
                            : null
                        }
                        {data.isActiveQuizz ?
                            <li><Link to="/quiz" className="fr-summary__link" title="Redirection sur la page : quiz">Quiz</Link></li>
                            : null
                        }
                        {data.isActiveArticleJury ?
                            <li><Link to="/jury" className="fr-summary__link" title="Redirection sur la page : Jury">Jury</Link></li>
                            : null
                        }
                        {data.isActiveArticlePersonnelCivil ?
                            <li><Link to="/personnel-civil" className="fr-summary__link" title="Redirection sur la page : Personnel civil">Personnel civil</Link></li>
                            : null
                        }
                        {data.isActiveDga ?
                            <li><Link to="/dga" className="fr-summary__link" title="Redirection sur la page : DGA">DGA</Link></li>
                            : null
                        }
                        {data.isActiveDgse ?
                            <li><Link to="/dgse" className="fr-summary__link" title="Redirection sur la page : DGSE">DGSE</Link></li>
                            : null
                        }
                        {data.isActiveArticleConcours ?
                            <li><Link to="/concours" title="Redirection vers : Article concours" className="fr-summary__link">Article concours</Link></li>
                            : null
                        }
                        <li><Link to="/metiers" title="Redirection vers : Nos métiers" className="fr-summary__link">Nos métiers</Link></li>
                        <li><Link to="/recrutement" title="Redirection vers : Recrutement" className="fr-summary__link">Rechercher une offre</Link></li>
                        <li><Link to="/admissio" title="Redirection vers : Concours civils" className="fr-summary__link">Rechercher un concours</Link></li>
                        <li><Link to="/mentions-legales" title="Redirection vers : Mentions légales" className="fr-summary__link">Mentions légales</Link></li>
                        <li><Link to="/accessibilite" title="Redirection vers : Accessibilité" className="fr-summary__link">Accessibilité</Link></li>
                        <li><Link to="/nous-contacter" title="Redirection vers : Contact" className="fr-summary__link">Contact</Link></li>
                    </ol>
                </nav>
            </main>
            <Footer />
        </>
    );
}

export default PlanDeSite;