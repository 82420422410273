import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import Parser from 'html-react-parser'
import { Link } from "react-router-dom"
import '../../sass/components/_darkmode.scss'
import { Facebook } from 'react-content-loader'

const Home = () => {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = 'homes.json';
    const TITLE = 'Civils de la Défense';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true)
            }).catch(err => {
                setError(err);
                setLoad(true);
            }
            );
    }, []);

    if (load) {
        return (
            <>
                <Helmet><title>{TITLE}</title></Helmet>
                <section className="fr-container fr-mt-10w">
                    {error
                        ? <p>{error.message}</p>
                        : data.map((GetData, index) =>
                            <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--middle" key={index}>
                                <div className="fr-col-12 fr-col-md-6">
                                    <h1>{Parser(GetData.titre)}</h1>
                                    {Parser(GetData.texte)}
                                    <Link to={Parser(GetData.url)} className="fr-btn fr-mt-5w" title={Parser(GetData.title)} target="_self">{Parser(GetData.cta)}</Link>
                                </div>

                                <div className="fr-col-12 fr-col-md-6 fr-mt-5w">
                                    <div>
                                        {GetData.media.contentUrl.endsWith('.webm') ?
                                            <video src={`${ApiConfig.baseURL_IMG + GetData.media.contentUrl}`} autoPlay muted loop /> :
                                            <img src={`${ApiConfig.baseURL_IMG + GetData.media.contentUrl}`} alt={GetData.alt} className="fr-responsive-img" />
                                        }
                                        <div className="filter-f"></div>
                                    </div>
                                    <p className="fr-text--xs ta-r">&copy; {GetData.media.contentUrl.endsWith('.webm') ? "Vidéo" : "Photo"} : Ministère des Armées</p>
                                </div>
                            </div>
                        )
                    }
                </section>
            </>
        );
    }

    else {
        return (
            <section className="fr-container fr-mt-15w">
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement des offres en cours.....' />
                </div>
            </section>
        );
    }
};


export default Home;