import React from 'react';
import Article from "../layout/Article";
const API_PATH = 'article_juries.json';
const TITLE = 'Civils de la Défense - Participer aux jurys'

const Article_Jury = () => {
    return (
        <div>
            <Article
                access_path={API_PATH}
                seo={TITLE}
            />

        </div>
    );
}

export default Article_Jury;