import React, { useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Footer from '../99_Footer/Footer';
import { ApiConfig } from '../Api/ApiConfig';

const Quizz_Home = () => {
    const TITLE = 'Civils de la Défense - Quiz';
    const [quizzes, setQuizzes] = useState([]);
    const API_PATH = 'quizzes.json';


    useEffect(() => {
        fetch(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => res.json()).then(res => {
                setQuizzes(res);
            })
            .catch(e => {
                console.log(new Error("Asynchronous error"));
            });
    }, []);  

    return (

        <>
            <Helmet><title>{TITLE}</title></Helmet>
            <section className="fr-container">
                <div className="fr-grid-row fr-grid-row--gutters fr-mt-5w">
                    <div className="fr-col">
                        <h1 className="ta-c">Quizz - Civils de la Défense</h1>
                    </div>
                </div>

                <div className="fr-grid-row fr-grid-row--gutters fr-mb-5w">
                    {quizzes.map((data, index) => (
                        <div className="fr-col-12 fr-col-md-4" key={index}>
                            <Link to={`/quiz/${data.id}`}>
                                <div className="fr-card">
                                    <div className="fr-card__body">
                                        <h2 className="fr-card__title">{data.theme}</h2>
                                        <p className="fr-card__desc fr-mt-2w">{data.description}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Quizz_Home;