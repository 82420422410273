import React from 'react';
import Article from "../layout/Article";

const API_PATH  = 'article_inclusions.json';
const TITLE = 'Civils de la Défense - Le ministère dans sa démarche inclusive'

const Article_Inclusion = () => {
    return (
        <Article
            access_path={API_PATH}
            seo={TITLE}
        />
    );
}


export default Article_Inclusion;