import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Parser from 'html-react-parser';

import { Carousel } from 'react-responsive-carousel';
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import { Facebook } from 'react-content-loader'
import { useNavigate } from "react-router-dom";

function Carrousel({}) {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = 'mission_categories.json';

    const navigate = useNavigate();

    const statusFormatter = (current, total) => `Catégorie ${current} sur ${total}`;

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true)
            }).catch(err => {
                setError(err);
                setLoad(true);
            }
            );
    }, []);

    if (load) {
        return (
            <>
                {
                    data.length === 0 ? null
                        : <section id="mission_category" className="fr-container" style={{ textAlign: "center" }}>
                            <h2 className="ta-c fr-pt-5w fr-mb-5w">{'Catégories de Mission'}</h2>
                            <Carousel
                                autoPlay={(true)}
                                infiniteLoop={(true)}
                                // Fleche
                                showArrows={(false)}
                                showStatus={(true)}
                                // Bullet point
                                showIndicators={(false)}
                                // Image sous le slider
                                showThumbs={(true)}
                                // Status 1of3
                                // Possibilité de Swipe avec la souris
                                swipeable={(true)}
                                emulateTouch={(true)}
                                useKeyboardArrows={(true)}
                                transitionTime={1000}
                                interval={5000}
                                statusFormatter={statusFormatter}
                            >

                                {error
                                    ? <p>{error.message}</p>
                                    : data.map((GetData, index) =>
                                        <section key={index} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {(["mp4", "avi", "webm", "mov"].includes(ApiConfig.baseURL_IMG + GetData.media.contentUrl.split(".").pop().toLowerCase())) ?
                                                <video src={`${ApiConfig.baseURL_IMG + GetData.media.contentUrl}`} autoPlay muted loop /> :
                                                <img src={ApiConfig.baseURL_IMG + GetData.media.contentUrl} alt={GetData.title} />
                                            }
                                            <div className="legend">
                                                <span className="fr-mb-1w title" style={{display: 'block'}}>{GetData.title}</span>
                                                <span className="texte">{ Parser(GetData.description ? GetData.description : '')}</span>
                                                <button onClick={() => navigate('/metiers', {state: {missionCategory: GetData}})} title="Voir les métiers - [Ouvrir dans une nouvelle fenêtre]" className="fr-btn fr-mt-1w">
                                                            Voir les métiers
                                                </button>
                                            </div>
                                        </section>
                                    ).reverse()
                                }
                            </Carousel>
                            <style>
                                {`
                        .legend .title {
                            font-size: 1rem !important;
                            line-height: 1rem !important;
                            font-weight: 800;
                            color: lightcoral;
                            }
                        .legend .texte p{
                            font-size: 0.75rem !important;
                            line-height: 0.75rem !important;
                            }
                        .legend a {
                            font-size: 0.75rem;
                            line-height: 0.75rem !important;
                            }
                    `}
                            </style>
                        </section>
                }

            </>
        );
    }

    else {
        return (
            <div>
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement en cours.....' />
                </div>
            </div>
        );
    }
};


export default Carrousel;

