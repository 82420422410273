import React from 'react';

import Article from "../layout/Article"
const API_PATH = 'article_chiffres.json';
const TITLE = 'Civils de la Défense - Le ministère en quelques chiffres'

const Article_Chiffres = () => {
    return (
        <Article
            access_path={API_PATH}
            seo={TITLE}
        />
    );
}

export default Article_Chiffres;