import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import Parser from 'html-react-parser'
import { Facebook } from 'react-content-loader'

const CommentNousRejoindre = () => {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = 'rejoindres.json';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true)
            }).catch(err => {
                setError(err);
                setLoad(true);
            }
            );
    }, []);

    if (load && data[0]) {
        return (
            <section id="rejoindre">
                <h2 className="ta-c fr-pt-5w fr-mb-5w">Comment nous rejoindre</h2>
                <div className="fr-mb-6v fr-container">
                    <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center fr-pb-5w">
                        {error
                            ? <p>{error.message}</p>
                            : data.map((GetData, index) =>
                                <div className="fr-col-12 fr-col-md-4" key={index}>
                                    <div className="fr-card fr-enlarge-link">
                                        <div className="fr-card__body">
                                            <p className="fr-card__detail ta-l">{Parser(GetData.title)}</p>
                                            <h3 className="fr-card__title">
                                                <a href={GetData.url} className="fr-card__link" title={Parser(GetData.title)}>{Parser(GetData.titre)}</a>
                                            </h3>
                                        </div>
                                        <div className="fr-card__img">
                                            {GetData.media.contentUrl.endsWith('.webm') ?
                                                <video src={`${ApiConfig.baseURL_IMG + GetData.media.contentUrl}`} autoPlay muted loop className="fr-responsive-video" /> :
                                                <img src={`${ApiConfig.baseURL_IMG + GetData.media.contentUrl}`} alt={GetData.title} className="fr-responsive-img" />
                                            }
                                            <div className="filter-f"></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="fr-card fr-enlarge-link">
                        <div className="fr-card__body fr-callout" style={{ marginBottom: "0" }}>
                            <p className="fr-card__detail ta-l">Fonction publique</p>
                            <h3 className="fr-card__title">
                                <Link to="/fonctionnaire" className="fr-card__link" title="Article fonction publique">Je suis déjà dans la fonction publique</Link>
                            </h3>
                            {/* <p className="fr-card__desc">La carte donne des aperçus cliquables d’une page de contenu à l’utilisateur. Elle fait généralement partie d'une collection ou liste d’aperçus de contenu similaires. La carte n’est jamais présentée de manière isolée.</p> */}
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    else if (load && !data[0]) {
        return null
    }

    else {
        return (
            <section className="fr-container fr-mt-15w">
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement des offres en cours.....' />
                </div>
            </section>
        );
    }
};


export default CommentNousRejoindre;