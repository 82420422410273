import React, { useEffect, useState } from 'react'
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import Parser from 'html-react-parser'
import { numberWithCommas } from "../Util/Chiffres"
import { Fade } from "react-awesome-reveal";
import { Facebook } from 'react-content-loader'

const Chiffres = () => {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = 'chiffres.json';

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true)
            }).catch(err => {
                setError(err);
                setLoad(true);
            }
            );
    }, []);

    if (load) {
        return (
            <section id="chiffres" className="fr-mt-10w">
                <div className="fr-container fr-bg--alt fr-centered fr-grid-row--center fr-pt-3w fr-pb-3w">
                    <div className="fr-grid-row fr-grid-row--center fr-grid-row--gutter">
                        {error
                            ? <p>{error.message}</p>
                            : data.map((GetData, index) =>
                                <div className="fr-col-xs-12 fr-col-md-6 fr-col-lg-3 fr-p-3w chiffres" key={index}>
                                    <Fade cascade top>
                                        <h2 className="rf-text--lead ta-c">{numberWithCommas(GetData.chiffre)}</h2>
                                        <p className="ta-c fr-mt-1w">{GetData.titre}</p>
                                        <span className="ta-c fr-mt-2w">{Parser(GetData.texte)}</span>
                                    </Fade>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        );
    }

    else {
        return (
            <section className="fr-container fr-mt-15w">
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement en cours.....' />
                </div>
            </section>
        );
    }
};


export default Chiffres;