import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Parser from 'html-react-parser';

import { Carousel } from 'react-responsive-carousel';
import { getData } from '../Api/getData'
import { ApiConfig } from '../Api/ApiConfig'
import { Facebook } from 'react-content-loader'
import { isWithinInterval } from 'date-fns';

function SliderCarrousel() {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const API_PATH = 'sliders.json';

    const statusFormatter = (current, total) => `Actualité ${current} sur ${total}`;

    useEffect(() => {
        getData(`${ApiConfig.baseURL + API_PATH}`)
            .then(res => {
                setData(res);
                setLoad(true)
            }).catch(err => {
                setError(err);
                setLoad(true);
            }
            );
    }, []);

    const filteredData = data.filter(slider => isWithinInterval(new Date(), { start: slider.startDate, end: slider.endDate}));

    if (load) {
        return (
            <>
            { filteredData.length > 0 ? <section id="slider" className="fr-container" style={{ textAlign: "center" }}>
                <h2 className="ta-c fr-pt-5w fr-mb-5w">{'Actualités'}</h2>
                <Carousel
                    autoPlay={(true)}
                    infiniteLoop={(true)}
                    // Fleche
                    showArrows={(false)}
                    showStatus={(true)}
                    // Bullet point
                    showIndicators={(false)}
                    // Image sous le slider
                    showThumbs={(true)}
                    // Status 1of3
                    // Possibilité de Swipe avec la souris
                    swipeable={(true)}
                    emulateTouch={(true)}
                    useKeyboardArrows={(true)}
                    transitionTime={1000}
                    interval={5000}
                    statusFormatter={statusFormatter}
                >

                    {error
                        ? <p>{error.message}</p>
                        : //Filtrage des actualités en fonction de leur date de debut/fin
                        filteredData.map((GetData, index) =>
                            <section key={index} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {(["mp4", "avi", "webm", "mov"].includes(GetData.image.split(".").pop().toLowerCase())) ?
                                    <video src={GetData.image} autoPlay muted loop /> :
                                    <img src={GetData.image} alt={GetData.image} />
                                }
                                <div className="legend">
                                    <span className="fr-mb-1w title">{GetData.titre}</span>
                                    <span className="texte">{Parser(GetData.texte)}</span>
                                    <a href={GetData.url} title="En savoir plus ? - [Ouvrir dans une nouvelle fenêtre]" className="fr-btn fr-mt-1w" target="_blank" rel="noopener noreferrer">En savoir plus ?</a>
                                </div>
                            </section>
                        ).reverse()
                    }
                </Carousel>
                <style>
                    {`
                        .legend .title {
                            font-size: 1rem !important;
                            line-height: 1rem !important;
                            font-weight: 800;
                            color: lightcoral;
                            }
                        .legend .texte p{
                            font-size: 0.75rem !important;
                            line-height: 0.75rem !important;
                            }
                        .legend a {
                            font-size: 0.75rem;
                            line-height: 0.75rem !important;
                            }
                    `}
                </style>
            </section> : null}
            </>
        );
    }

    else {
        return (
            <div>
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement en cours.....' />
                </div>
            </div>
        );
    }
};


export default SliderCarrousel;

