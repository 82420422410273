import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Scroll from '../Util/Scroll';
import Footer from '../99_Footer/Footer';
import { Facebook } from 'react-content-loader';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';

const Quiz = ({ data }) => {
    const { id } = useParams();
    const [quiz, setQuiz] = useState();
    const [load, setLoad] = useState(false);
    const TITLE = 'Civils de la Défense - Quiz'

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const { width, height } = useWindowSize()

    const Application = ['https://media.giphy.com/media/nC30bN4yizgek/source.gif', 'https://media.giphy.com/media/mEOnc4lfBcKdeAdgsa/giphy.gif', 'https://media.giphy.com/media/D8Qkgu0gYAjS/source.gif'];
    const Maitrise = ['https://media.giphy.com/media/8vQSQ3cNXuDGo/giphy.gif', 'https://media.giphy.com/media/kbfwRGphifex2/source.gif', 'https://media.giphy.com/media/3R9LDINpbGX2o/giphy.gif'];
    const Expert = ['https://media.giphy.com/media/3oz8xRF0v9WMAUVLNK/source.gif', 'https://media.giphy.com/media/5PSPV1ucLX31u/giphy.gif'];


    useEffect(() => {
        setQuiz(data.find((p) => p.id === parseInt(id, 10)));
        setLoad(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const Result = () => {
        if (score <= 0.4 * quiz.questions.length) {
            return (
                <>
                    <h1 className="titre-quizz">Aïe... vous pouvez mieux faire !</h1>
                    <img src={Application[Math.floor(Math.random() * Application.length)]} alt="Niveau Application" className="img-quizz" />
                </>
            )
        } else if (score > 0.4 * quiz.questions.length && score <= 0.7 * quiz.questions.length) {
            return (
                <>
                    <h1 className="titre-quizz">Vous avez de bonnes connaissance dans le domaine, <br />encore un effort et vous serez incollable !</h1>
                    <img src={Maitrise[Math.floor(Math.random() * Maitrise.length)]} alt="Niveau Maitrise" className="img-quizz" />
                </>
            )
        } else if (score > 0.7 * quiz.questions.length) {
            return (
                <>
                    <Confetti
                        width={width}
                        height={height}
                    />
                    <h1 className="titre-quizz">Bravo ! Vous êtes un(e) véritable expert(e) !</h1>
                    <img src={Expert[Math.floor(Math.random() * Expert.length)]} alt="Niveau Expert(e)" className="img-quizz" />
                </>
            )
        }
    }

    const handleanswersClick = (isValid) => {
        if (isValid) {
            setScore(score + 1);
        }

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < quiz.questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };
    
    if (load && quiz) {
        const random = quiz.questions[currentQuestion].answers.sort(() => Math.random() - Math.random()).find(() => true);
        return (
            <>
                <Scroll />
                <Helmet><title>{TITLE + ' ' + quiz.id + ' (' + quiz.theme + ')'}</title></Helmet>
                <section>
                    {showScore ? (
                        <div className="ta-c">
                            <p className="ta-c fr-pt-10w">Votre score est de <b>{score}</b> / {quiz.questions.length}</p>
                            <div className="ta-c fr-mb-5w">{Result()}</div>

                            <div className="fr-pb-5w">
                                <Link to="/" title="Revenir sur la page d'accueil - Civils de la Défense" className="fr-btn fr-btn--secondary" style={{ marginRight: "1%" }}>Retour à l'accueil</Link>
                                <Link to="/quiz" title="Réessayer notre Quizz" className="fr-btn">Réessayer</Link>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="fr-container ta-c fr-bg--alt fr-centered fr-grid-row--center fr-pt-10w fr-pb-5w">
                                    <h1 className="fr-pt-3w"><span>Question {currentQuestion + 1}</span>/{quiz.questions.length}</h1>
                                    <p>{quiz.questions[currentQuestion].title}</p>
                                    {quiz.questions[currentQuestion].help ?
                                        <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center fr-mt-2w">
                                            <div className="fr-alert fr-alert--info fr-col-6">
                                                <h3 className="fr-alert__title">Indications :</h3>
                                                <p>{quiz.questions[currentQuestion].help}</p>
                                            </div>
                                        </div>
                                    : null}
                            </div>

                            <section className="fr-pt-5w fr-container ta-c fr-bg--alt fr-centered fr-grid-row--center">
                                <div className="fr-grid-row fr-grid-row--gutters fr-mb-5w">
                                        {random && quiz.questions[currentQuestion].answers.map((answers, index) => (
                                            <div className="fr-col-xs-12 fr-col-md-6 fr-col-lg-3 quizz-grid" key={index}>
                                            <div className="fr-card">
                                                <button onClick={() => handleanswersClick(answers.isValid)} style={{ height: "inherit" }}>
                                                    <div className="fr-card__body">
                                                        <p className="fr-card__desc fr-mt-3w fr-pt-3w">
                                                            {answers.title}
                                                        </p>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        </>
                    )}
                </section>
                <Footer />
            </>
        );
    }

    else {
        return (
            <>
                <div className="fr-mb-6v fr-container">
                    <Facebook title='Chargement des quizs en cours.....' />
                </div>
            </>
        );
    }
};

export default Quiz;