import React from 'react';

import Article from "../layout/Article";
const API_PATH = 'article_jeunesses.json';
const TITLE = 'Civils de la Défense - Votre première expérience au cœur de la défense'

const Article_Jeunesse = () => {
    return (
        <Article
            access_path={API_PATH}
            seo={TITLE}
        />
    );
}

export default Article_Jeunesse;